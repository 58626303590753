<template>
  <div>
    <v-card>
      <v-card-title>
        Users with pending loans:
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="users"
        :search="search"
        class="elevation-1"
        show-expand
        :single-expand="true"
        item-key="email"
        :loading="loadingUsers"
        loading-text="Loading... Please wait"
        no-data-text="No loans found."
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
      >
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <tr>
              <ViewLoanForm
                v-for="assetInstance in item.loan"
                :key="assetInstance._id"
                :user="item"
                :assetInstance="assetInstance"
                @instanceUpdated="fetchLoansByUsers"
                @returnDone="returnFeedback"
                @close="$emit('close')"
              >
              </ViewLoanForm>
            </tr>
          </td>
        </template>
      </v-data-table>
    </v-card>
    <v-snackbar v-model="successBar" :timeout="5000" color="success">
      <strong>{{ success }} </strong>
    </v-snackbar>
    <v-snackbar v-model="errorBar" :timeout="5000" color="red">
      <strong>{{ error }} </strong>
    </v-snackbar>
  </div>
</template>


<script>
import ViewLoanForm from "./ViewLoanForm";

export default {
  name: "ViewLoans",

  data: () => ({
    loadingUsers: true,
    users: [],
    headers: [
      { text: "LastName", value: "second_name" },
      { text: "eMail", value: "email" },
      { text: "Role", value: "role" },
      { text: "Reserved", value: "cart.length" },
      { text: "Borrowed", value: "loan.length" },
      { text: "Owned", value: "owned.length" },
      { text: "Can Borrow", value: "can_borrow" },
    ],
    search: "",

    sortBy: "loan.length",
    sortDesc: true,

    successBar: false,
    success: "",
    errorBar: false,
    error: "",
  }),

  components: {
    ViewLoanForm,
  },
  methods: {
    fetchLoansByUsers() {
      // Post request to retrieve the user array
      //console.log("retrieving all the user with pending loans...");

      const headers = {
        Authorization: "Bearer " + this.$store.getters.auth_jwt,
      };

      // Make a request for a user with a given ID
      this.$http
        .get(process.env.VUE_APP_API_ENDPOINT + "/auditor/getLoansByUser", {
          headers,
        })
        .then((response) => {
          // handle success
          //console.log(response.data);
          this.users = response.data;
          this.loadingUsers = false;
        })
        .catch((error) => {
          // handle error
          console.log(error.response.data);
        })
        .then(function () {
          // always executed
        });
    },

    returnFeedback(responseData) {
      this.success = responseData;
      this.successBar = true;
    },
  },

  mounted: function () {
    this.fetchLoansByUsers();
  },
};
</script>