<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-chip  class="ma-2" color="primary" v-bind="attrs" v-on="on" outlined>
        {{loanInstance.asset.name + '(' + loanInstance.dip_id + ')'}}
      </v-chip>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">Asset: {{loanInstance.asset.name}}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row >   
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
            User: <strong> {{user.username}} </strong> reserved instance: <strong> {{loanInstance.dip_id}} </strong> <br>
            User request notes: <strong> {{loanInstance.reservation_note}} </strong> <br>
            </v-col>              
          </v-row>
          <v-divider/>
          <br>
          <v-row  v-if="availableInstances.length > 0">
            <v-col cols="12" sm="12">
              <v-select
                :items="availableInstancesDipId"
                v-model="select"
                label="Change the instance to loan"
                :clearable="true"
                @change="updateLoanInstance"
              ></v-select>
            </v-col>
          </v-row>
          <v-divider v-if="availableInstances.length > 0"></v-divider>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              Desired return date: <strong> {{beautifyDate(loanInstance.desired_due_back)}} </strong>
            </v-col>
          </v-row>
          <v-row v-if="modifyDate">
            <v-col cols="12" sm="12" md="12">
              New return date:  <strong> {{newDueBackFormatted}} </strong>
            </v-col>
          </v-row>
          <v-switch
            v-model="modifyDate"
            :label="'Modify return date'"
          ></v-switch>
          <v-row justify="center" v-show="modifyDate">
            
            <v-col cols="12" sm="12">
                <v-menu
                  ref="menu1"
                  v-model="dateMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="newDueBackFormatted"
                      label="Return Date"
                      persistent-hint
                      prepend-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      :clearable="true"
                      :readonly="true"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="newDueBack"
                    no-title
                    @input="dateMenu = false; newDueBackFormatted = beautifyDate(newDueBack); updateReservationDuebackDate()"
                  ></v-date-picker>
                </v-menu>
              </v-col>
          </v-row>
          <v-divider/>
          <br>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-switch
              v-model="loanInstance.reservation_ready"
              label="Set this reservation ready to loan" @change="updateReservationReadiness"
              inset
              color="success"
            ></v-switch>
            </v-col>
          </v-row>        

        </v-container>
        
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="close"
        >
          Save & Close 
        </v-btn>
        <v-btn
          color="red"
          text
          @click="loan"
        >
          Loan
        </v-btn>
      </v-card-actions>
      <v-snackbar v-model="successBar" :timeout="5000" color="success">
        <strong>{{ success }} </strong>
      </v-snackbar>
      <v-snackbar v-model="errorBar" :timeout="5000" color="red">
        <strong>{{ error }} </strong>
      </v-snackbar>
    </v-card>
  </v-dialog>
</template>


<script>

  // My beauty date formatter
  import {BeautyDate} from '../../utils/BeautyDate' // eslint-disable-line no-unused-vars

  export default {

    name: 'HandleReservationForm',

    props: {
        user: Object,
        assetInstance: Object,
    },

    data: () => ({
        dialog: false,
        successBar: false,
        success: "",
        errorBar: false,
        error: "",
        availableInstances: [],
        availableInstancesDipId: [],
        select: null,
        modifyDate: false,
        // The instane we are going to loan, it could be the user reserved one or another chosen by manager
        loanInstance: null,

        dateMenu:false,
        newDueBackFormatted: null,
        newDueBack: null,
    }),

    methods : {

        beautifyDate(date) {
          if(date){
            return BeautyDate(new Date(date));
          }
          
        },

        // Retrieve other assetInstace available (this gives the manager the possibility to choose another instance of this asset to loan)
        fetchOtherAvailableIsntances(){
          // Used to update the list of available instances
          // If another instances is chosen then we should remember to reset also the original instance during loan method
            // Request to retrieve the assets array
            //console.log('retrieving available assets...');
            //console.log(this.loanInstance);

            const headers = {
                'Authorization': 'Bearer ' + this.$store.getters.auth_jwt
            };

            // Make a request for available instances of an asset
            this.$http.get(process.env.VUE_APP_API_ENDPOINT +'/manager/getAvailableInstances/' + this.loanInstance.asset._id , {headers})
            .then(response => {
                // handle success
                //console.log(response.data);
                this.availableInstances = response.data;
                

                // Reset the DipId array
                this.availableInstancesDipId = [];

                // Fill the human readable array to display available instances
                for( let instance of this.availableInstances){

                  this.availableInstancesDipId.push(instance.dip_id)
                }
                
            })
            .catch(error => {
                // handle error
                console.log(error);
            })
            .then(function () {
                // always executed
            });
        },

        // Method called by v-selectable component when manager choose to modify the asset instance to loan
        updateLoanInstance(loanInstanceId) {

          //console.log(this.loanInstance);

          // To avoid two instances reamain reserved, we are going to copy reservation info to the new instance selected and remove them from the old one
          //TODO
          // end point will be switchInstanceReservation
          // Passo old instance id, new instance id, user per modificare il suo carrello,
          // Mi ritorna la nuova instance della quale poi eseguirò il loan
          const headers = {
          'Authorization': 'jwt ' + this.$store.getters.auth_jwt
          };

          const indexOfLoanInstance = this.availableInstancesDipId.indexOf(loanInstanceId);
          
          //console.log(indexOfLoanInstance);

          const body = {
            fromId: this.loanInstance._id,
            toId: this.availableInstances[indexOfLoanInstance]._id,
            userId: this.user._id
          }

          this.$http.put(process.env.VUE_APP_API_ENDPOINT +'/manager/switchInstanceReservation', body, {headers})
          .then(response => {
              //console.log(response);
              if(response.status === 200){

                  //console.log(response.data);
                  //console.log("Switch instances done");
                  this.success = "Instance reservation updated"
                  this.successBar = true;

                  this.loanInstance = response.data;

                  this.select = 0;

                  // Fetch the new availble liste... the old instance should now be available again
                  this.fetchOtherAvailableIsntances();

                  //
                  //console.log(this.assetInstance);

                  // Emit signal to refresh the parent property sent to this child (assetInstance)
                  //this.$emit('instanceUpdated');

                  //console.log(this.assetInstance);

              }
          })
          .catch(err => {
              console.log('Error happened...');
              this.errorBar = true
              this.error = err.response.data
          });

        },

        // The manager change the dueback date, notify the user that the dueback date changed
        updateReservationDuebackDate(){
          

          const headers = {
          'Authorization': 'jwt ' + this.$store.getters.auth_jwt
          };

          const body = {
            assetInstanceId: this.loanInstance._id,
            due_back: this.newDueBack,
          }

          this.$http.put(process.env.VUE_APP_API_ENDPOINT +'/manager/setReservationDueback', body, {headers})
          .then(response => {
              //console.log(response);
              if(response.status === 200){

                  //console.log("Reservation dueback date updated");
                  this.success = response.data;
                  this.successBar = true;
              }
          })
          .catch(err => {
              console.log('Error happened...');
              this.errorBar = true
              this.error = err.response.data
          });
          
        },

        // The manager, settin the reservation ready to loan, notify the user that the reservation is now ready
        updateReservationReadiness(){
          
          const headers = {
          'Authorization': 'jwt ' + this.$store.getters.auth_jwt
          };

          const body = {
            assetInstanceId: this.loanInstance._id,
            ready: this.loanInstance.reservation_ready,
          }

          this.$http.put(process.env.VUE_APP_API_ENDPOINT +'/manager/setReservationReadyness', body, {headers})
          .then(response => {
              //console.log(response);
              if(response.status === 200){

                  //console.log("Reservation readyness updated");
                  this.success = response.data;
                  this.successBar = true;
              }
          })
          .catch(err => {
              console.log('Error happened...');
              this.errorBar = true
              this.error = err.response.data
          });
          
        },

        // Ready to loan an assetInstance
        // This method reset the reservation fields of the assetInstance that we are goin to loan and modify the assetInstance state
        loan(){

          //console.log(this.user._id + ' -> ' + this.user.username + ' $ ' + this.loanInstance._id + ' -> ' + this.loanInstance.asset.name);
          // Prepare the message
          const headers = {
          'Authorization': 'jwt ' + this.$store.getters.auth_jwt
          };

          const body = {
            assetInstanceId: this.loanInstance._id
          }

          this.$http.put(process.env.VUE_APP_API_ENDPOINT +'/manager/borrowAssetInstance', body, {headers})
          .then(response => {
              //console.log(response);
              if(response.status === 200){

                  //console.log("Asset instance loan done ");
                  //console.log(response.data);
                  this.$emit('loanDone', response.data);
                  this.close();

              }
          })
          .catch(err => {
              console.log('Error happened...');
              this.errorBar = true
              this.error = err.response.data
          });
        },

        close() {
            // Emit that the reservation form has closed, we are going to refresh the asset list
            this.$emit('instanceUpdated');
            this.$emit('close')
            this.errorBar = false;
            this.dialog = false;
        }
    },

    created: function (){ 

      this.loanInstance = this.assetInstance;

      // If this reservation has already a dueback date... show it
      if(this.loanInstance.due_back != this.loanInstance.desired_due_back){
        this.modifyDate = true;
        this.newDueBackFormatted = this.beautifyDate(this.loanInstance.due_back);
      }
      
      
      // Fetch other available instances of this asset, useful if we need to change the instance
      this.fetchOtherAvailableIsntances();
    }
  }
</script>