<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-chip class="ma-2" color="primary" v-bind="attrs" v-on="on" outlined>
        {{ loanInstance.asset.name }}
      </v-chip>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">Asset: {{ loanInstance.asset.name }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              User:
              <strong> {{ user.first_name }} {{ user.second_name }} </strong>
              <br />
              Instance: <strong> {{ loanInstance.dip_id }} </strong> <br />
              Expected date of return:
              <strong> {{ beautifyDate(loanInstance.due_back) }} </strong>
            </v-col>
          </v-row>
          <v-divider />
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="close"> Back </v-btn>
      </v-card-actions>
      <v-snackbar v-model="successBar" :timeout="5000" color="success">
        <strong>{{ success }} </strong>
      </v-snackbar>
      <v-snackbar v-model="errorBar" :timeout="5000" color="red">
        <strong>{{ error }} </strong>
      </v-snackbar>
    </v-card>
  </v-dialog>
</template>


<script>
// My beauty date formatter
import { BeautyDate } from "../../utils/BeautyDate"; // eslint-disable-line no-unused-vars

export default {
  name: "ViewLoanForm",

  props: {
    user: Object,
    assetInstance: Object,
  },

  data: () => ({
    dialog: false,
    successBar: false,
    success: "",
    errorBar: false,
    error: "",
    // The instance we are going to loan, it could be the user reserved one or another chosen by manager
    loanInstance: null,
  }),

  methods: {
    beautifyDate(date) {
      if (date) {
        return BeautyDate(new Date(date));
      }
    },

    close() {
      // Emit that the form has closed
      this.$emit("close");
      this.errorBar = false;
      this.dialog = false;
    },
  },

  created: function () {
    this.loanInstance = this.assetInstance;
  },
};
</script>