<template>
  <v-item-group>
    <v-row justify="center">
    <v-dialog
      v-model="this.assetBigPreview.show"
      persistent
      max-width="500"
    >
      <v-card>
        <v-card-title class="text-h5">
          {{ assetBigPreview.name }}
        </v-card-title>
        <v-card-text>{{ assetBigPreview.description }}</v-card-text>
        <v-img
          max-width="500"
          :src="this.assetBigPreview.src"
        ></v-img>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="closePreviewImage"
          >
            CLOSE
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
    <v-container>
      <v-snackbar v-model="reservationStatus" :timeout="5000" color="success">
        <strong>
          Reservation accepted! You will be notified when it's ready to pick
          up</strong
        >
      </v-snackbar>

      <v-row class="my-2">
        <v-text-field
          ref="search"
          v-model="search"
          full-width
          hide-details
          label="Search"
          single-line
          @input="searchFilter"
        ></v-text-field>
      </v-row>
      <v-row>
        <div class="mx-2 my-2" v-for="tag in tagsList" :key="tag.name">
          <v-badge :content="tag.occurrences" overlap>
            <v-btn @click="filterAssets(tag.name)">
              {{ tag.name }}
            </v-btn>
          </v-badge>
        </div>
      </v-row>
      <v-row>
        <AssetPreview
          v-for="asset in filtered"
          class="ma-2"
          :key="asset._id"
          :asset="asset"
          @openImage="openPreviewImage"
          @close="refresh"
        >
        </AssetPreview>
      </v-row>
    </v-container>
  </v-item-group>
</template>


<script>
import AssetPreview from "./AssetPreview";

export default {
  name: "ShowAssets",

  components: {
    AssetPreview,
  },

  data: () => ({
    assets: [],
    filtered: [],
    tagsList: [],
    search: "",
    reservationStatus: false,
    assetBigPreview:{
      show: false,
      name: "",
      description: "",
      src: ""
    },
  }),

  methods: {
    fetchAssets() {
      // Post request to retrieve the assets array
      //console.log("retrieving available assets...");

      const headers = {
        Authorization: "Bearer " + this.$store.getters.auth_jwt,
      };

      // Make a request for a user with a given ID
      this.$http
        .get(process.env.VUE_APP_API_ENDPOINT + "/common/assets", { headers })
        .then((response) => {
          // handle success

          //Reset old retrieved assets
          this.assets = [];

          //console.log(response.data);
          let allAssets = response.data;

          // Display only assets with some instances
          for (let asset of allAssets) {
            if(asset.nOfAvailableInstances > 0){
              this.assets.push(asset);
              //console.log(asset);
            }
          }
          // After assets received filters the tags
          this.extractTags();

          // Display initially all the assets (no filtering)
          this.filtered = this.assets;

          
          
        })
        .catch((error) => {
          // handle error
          console.log(error);
        })
        .then(function () {
          // always executed
        });
    },

    extractTags() {
      let tags = [];
      let tagOccurrences = [];

      // Obtain the uniqe list of tags of the all assets, and for each tag the number of occurrency
      //console.log(this.assets)
      // For each asset evluate its tags
      for (let asset of this.assets) {
        // Add every asset tag to a comprensive list
        for (let tag of asset.tags) {
          tags.push(tag);
        }
      }
      //console.log(tags);

      // Set all the tags lowercase to avoid case sensitive problems
      var tagLowercase = tags.map(function (v) {
        return v.toLowerCase();
      });

      let uniqueTags = tagLowercase.unique();

      // Order alphabetically the list
      uniqueTags.sort();

      //("tags list ordered: " + uniqueTags);
      //console.log(uniqueTags);

      for (let tag of uniqueTags) {
        let nOfOccu = tags.countOccurrences(tag);
        tagOccurrences.push({ name: tag, occurrences: nOfOccu });
      }
      //console.log(tagOccurrences);

      this.tagsList = tagOccurrences;
    },

    filterAssets(tag) {
      this.filtered = [];
      //console.log(tag);
      for (let asset of this.assets) {
        if (asset.tags && asset.tags.includes(tag)) {
          this.filtered.push(asset);
        }
      }
    },

    searchFilter() {
      this.filtered = [];
      let s = this.search.toLowerCase().trim();
      //console.log(s);

      for (let asset of this.assets) {
        //console.log(asset.name);
        if (
          (asset.name && asset.name.toLowerCase().includes(s)) ||
          (asset.description && asset.description.toLowerCase().includes(s)) ||
          (asset.tags && asset.tags.includes(s))
        ) {
          this.filtered.push(asset);
        }
      }
    },

    refresh(reservationStatus) {
      //console.log("refresh the list...");
      this.fetchAssets();

      //console.log("reservation status: " + reservationStatus);
      // visibility of the allert, notification when reservation ready
      this.reservationStatus = reservationStatus;
    },

    openPreviewImage(asset){
      //console.log("going to open preview image..." + src);
      this.assetBigPreview.name = asset.name
      this.assetBigPreview.description = asset.description;
      this.assetBigPreview.src = process.env.VUE_APP_API_ENDPOINT + '/' + asset.image;
      this.assetBigPreview.show = true;
    },

    closePreviewImage(){
      //console.log("going to close preview image..." + src);
      this.assetBigPreview.src = "";
      this.assetBigPreview.show = false;
    }
  },

  mounted: function () {
    // Add the unique function to the Array object (return unique value of a list)
    Array.prototype.unique = function () {
      return this.filter(function (value, index, self) {
        return self.indexOf(value) === index;
      });
    };
    //////////////////////////////////////////////////////////////////////////////

    // Add the count occurrences function to the Array object (return number of occurrences of an element of a list)
    Array.prototype.countOccurrences = function (val) {
      return this.reduce(
        (a, v) => (v.toLowerCase() === val.toLowerCase() ? a + 1 : a),
        0
      );
    };
    //////////////////////////////////////////////////////////////////////////////

    this.fetchAssets();
  },
};
</script>