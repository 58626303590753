<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="headline">Select User or Instance history:</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-container class="px-0" fluid>
            <v-switch
              v-model="searchUser"
              :label="'User history'"
              @change="searchInstance = !searchUser; resetInputs()"
            ></v-switch>
            <v-switch
              v-model="searchInstance"
              :label="'Instance history'"
              @change="searchUser = !searchInstance; resetInputs()"
            ></v-switch>
          </v-container>
          <v-row v-if="searchUser">
            <v-col class="d-flex" cols="12" sm="6">
              <v-select
                v-model="selectedUserId"
                :items="users"
                item-text="username"
                item-value="_id"
                label="Select the user"
                @change="fetchUserEvents"
                dense
              ></v-select>
            </v-col>
          </v-row>
          <div v-else>
            <v-row>
              <v-col class="d-flex" cols="12" sm="6">
                <v-select
                  v-model="selectedAssetId"
                  :items="assets"
                  item-text="name"
                  item-value="_id"
                  @change="selectAsset"
                  label="Select the asset"
                  dense
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex" cols="12" sm="6">
                <v-select
                  v-model="selectedInstanceId"
                  :items="instances"
                  item-text="dip_id"
                  item-value="_id"
                  @change="fetchInstanceEvents"
                  label="Select the instance"
                  dense
                ></v-select>
              </v-col>
            </v-row>
          </div>
        </v-container>
      </v-card-text>
    </v-card>
    <v-timeline>
      <UserEventItem
        v-for="event in userEvents"
        :key="event._id"
        :event="event"
      ></UserEventItem>

      <InstanceEventItem
        v-for="event in instanceEvents"
        :key="event._id"
        :event="event"
      ></InstanceEventItem>
    </v-timeline>
  </div>
</template>

<script>
//import ... from "./";
import UserEventItem from "./UserEventItem";
import InstanceEventItem from "./InstanceEventItem";

export default {
  name: "ManagerHistory",

  components: {
    UserEventItem,
    InstanceEventItem,
  },

  props: {},

  data: () => ({
    searchUser: true,
    searchInstance: false,
    users: [],
    assets: [],
    instances: [],
    selectedUserId: null,
    selectedAssetId: null,
    selectedInstanceId: null,

    userEvents: [],
    instanceEvents: [],
  }),

  methods: {

    // Reset input fields every important change
    resetInputs(){

      this.selectedUserId = null;
      this.selectedAssetId = null;
      this.selectedInstanceId = null;

      this.userEvents=[];
      this.instanceEvents = []

    },


    fetchUsers() {
      // Post request to retrieve the user array
      //console.log("retrieving all the user that login at least once...");

      const headers = {
        Authorization: "Bearer " + this.$store.getters.auth_jwt,
      };

      // Make a request for a user with a given ID
      this.$http
        .get(process.env.VUE_APP_API_ENDPOINT + "/admin/getUsers", { headers })
        .then((response) => {
          // handle success
          //console.log(response.data);
          this.users = response.data;
        })
        .catch((error) => {
          // handle error
          console.log(error);
        })
        .then(function () {
          // always executed
        });
    },

    fetchAssets() {
      // Post request to retrieve the assets array
      //console.log("retrieving available assets...");

      const headers = {
        Authorization: "Bearer " + this.$store.getters.auth_jwt,
      };

      // Make a request for a user with a given ID
      this.$http
        .get(process.env.VUE_APP_API_ENDPOINT + "/common/assets", { headers })
        .then((response) => {
          this.assets = response.data;
        })
        .catch((error) => {
          // handle error
          console.log(error);
        })
        .then(function () {
          // always executed
        });
    },

    selectAsset() {
      //console.log(this.selectedAssetId);
      this.fetchInstances(this.selectedAssetId);
    },

    // Retrieve assetInstace available
    fetchInstances(assetId) {
      // Used to update the list of available instances
      //console.log("retrieving all asset instances...");

      const headers = {
        Authorization: "Bearer " + this.$store.getters.auth_jwt,
      };

      // Make a request for available instances of an asset
      this.$http
        .get(
          process.env.VUE_APP_API_ENDPOINT +
            "/manager/getInstances/" +
            assetId,
          { headers }
        )
        .then((response) => {
          // handle success
          //console.log(response.data);
          this.instances = response.data;
        })
        .catch((error) => {
          // handle error
          console.log(error);
        })
        .then(function () {
          // always executed
        });
    },

    fetchUserEvents() {
      // Post request to retrieve the user array
      //console.log("retrieving all the user events");

      const headers = {
        Authorization: "Bearer " + this.$store.getters.auth_jwt,
      };

      // Make a request for a user with a given ID
      this.$http
        .get(
          process.env.VUE_APP_API_ENDPOINT +
            "/manager/getUserHistoryEvents/" +
            this.selectedUserId,
          {
            headers,
          }
        )
        .then((response) => {
          // handle success
          //console.log(response.data);
          this.userEvents = response.data;
        })
        .catch((error) => {
          // handle error
          console.log(error);
        })
        .then(function () {
          // always executed
        });
    },

    fetchInstanceEvents() {
      // Post request to retrieve the user array
      //console.log("retrieving all the instance events");

      const headers = {
        Authorization: "Bearer " + this.$store.getters.auth_jwt,
      };

      // Make a request for a user with a given ID
      this.$http
        .get(
          process.env.VUE_APP_API_ENDPOINT +
            "/manager/getInstanceHistoryEvents/" +
            this.selectedInstanceId,
          {
            headers,
          }
        )
        .then((response) => {
          // handle success
          //console.log(response.data);
          this.instanceEvents = response.data;
        })
        .catch((error) => {
          // handle error
          console.log(error);
        })
        .then(function () {
          // always executed
        });
    },
  },

  created: function () {
    this.fetchUsers();
    this.fetchAssets();
  },
};
</script>