<template>
<v-item-group>
    <v-container>
        <h1>
            Reserved items:
        </h1>
        <v-row v-if="$store.getters.cart.length == 0" class="ma-6">
            <h4>
                (No item currently reserved)
            </h4>
        </v-row>
        <v-row>
            <CartItemPreview v-for="assetInstance in $store.getters.cart"
                class="ma-2"
                :key="assetInstance._id"
                :assetInstance="assetInstance"
                >
            </CartItemPreview>
        
            
        </v-row>
    </v-container>
  </v-item-group>
</template>

<script>

import CartItemPreview from './CartItemPreview'

export default {
    
    name: 'Cart',

    components: {
      CartItemPreview,

    },
}
</script>