<template>
  <v-timeline-item>{{event.user.username}} - {{event.action}} of {{event.assetInstance.dip_id}} @ ({{event.date}})</v-timeline-item>
</template>

<script>

// My beauty date formatter
import { BeautyDate } from "../../../utils/BeautyDate"; // eslint-disable-line no-unused-vars

export default {
  name: "UserHistoryEventItem",

  props: {
    event: Object,
  },

  data: () => ({}),

  components: {},

  methods: {
    beautifyDate(date) {
      //console.log(this.returnDate);

      if (date) {
        return BeautyDate(new Date(date));
      }
    },
  },
};
</script>