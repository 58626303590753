var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-timeline-item',{attrs:{"color":_vm.event.action == 'Creation'
      ? 'pink'
      : _vm.event.action == 'Reservation'
      ? 'orange'
      : _vm.event.action == 'CancelReservation'
      ? 'red'
      : _vm.event.action == 'Borrow'
      ? 'primary'
      : _vm.event.action == 'Return'
      ? 'success'
      : 'black',"small":""}},[_c('div',{staticClass:"py-4"},[_c('h2',{class:`headline font-weight-bold mb-4 primary--text`},[_vm._v(" "+_vm._s(_vm.event.action)+" ")]),_c('div',[_vm._v(_vm._s(_vm.event.user.username)+" @ "+_vm._s(_vm.event.date))])])])
}
var staticRenderFns = []

export { render, staticRenderFns }