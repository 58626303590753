<template>
<H1>
    Statistics:
</H1>
</template>

<script>


export default {
    
    name: 'Statistics',

    components: {
    
    },
}
</script>