<template>
  <div>
    <v-card>
      <v-card-title>
        Asset list:
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-snackbar v-model="alertVisible" :timeout="3000" color="success">
        <strong>{{ modifySuccess }} </strong>
      </v-snackbar>
      <v-data-table
        :headers="headers"
        :items="assets"
        :search="search"
        class="elevation-1"
        show-expand
        :single-expand="true"
        item-key="_id"
        :loading="loadingAssets"
        loading-text="Loading... Please wait"
        no-data-text="No asset found."
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
      > 
        <template v-slot:item.name="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-chip primary v-on="on">{{ item.name }}</v-chip>
            </template>
            <span>
              <v-img
                max-width="80"
                :src="imgInitialPath + item.thumbnail"
              ></v-img>
            </span>
          </v-tooltip>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <tr>
              <ModifyInstanceForm
                ref="modifyInstanceFormRef"
                v-for="instance in item.instances"
                :key="instance._id"
                :assetInstance="instance"
                @instanceModified="instanceModified"
                @instanceDeleted="instanceDeleted"
                @close="$emit('close')"
              >
              </ModifyInstanceForm>

              <AddInstancesForm
                :asset="item"
                @instancesAdded="instancesAdded"
                @close="$emit('close')"
              >
              </AddInstancesForm>
            </tr>
          </td>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.actions="{ item }">
          <!--<ModifyAssetForm
            :asset="item"
            @assetModified="assetModified"
            @close="$emit('close')"
          >-->
          <ModifyAssetForm
            :asset="item"
            @assetModified="assetModified"
            @close="$emit('close')"
          >
          </ModifyAssetForm>
        </template>
      </v-data-table>
    </v-card>
    <v-snackbar v-model="successBar" :timeout="5000" color="success">
      <strong>{{ success }} </strong>
    </v-snackbar>
    <v-snackbar v-model="errorBar" :timeout="5000" color="red">
      <strong>{{ error }} </strong>
    </v-snackbar>
  </div>
</template>

<script>
import ModifyAssetForm from "./ModifyAssetForm";
import ModifyInstanceForm from "./ModifyInstanceForm";
import AddInstancesForm from "./AddInstancesForm";

export default {
  name: "ModifyAssets",

  components: {
    ModifyAssetForm,
    ModifyInstanceForm,
    AddInstancesForm,
  },

  data: () => ({
    loadingAssets: true,
    assets: [],
    headers: [
      { text: "Name", value: "name",filterable: true },
      { text: "Count", value: "instances.length", filterable: false },
      { text: "Description", value: "description", filterable: false },
      { text: "tags", value: "tags", filterable: true },
      { text: "Actions", value: "actions", sortable: false, filterable: false },
    ],
    search: "",
    sortBy: "name",
    sortDesc: false,
    alertVisible: false,
    modifySuccess: "",

    successBar: false,
    success: "",
    errorBar: false,
    error: "",

    imgInitialPath: process.env.VUE_APP_API_ENDPOINT + '/',
  }),
  methods: {
    fetchAssets() {
      // Post request to retrieve the assets array
      //console.log("retrieving available assets...");

      const headers = {
        Authorization: "Bearer " + this.$store.getters.auth_jwt,
      };

      // Make a request for a user with a given ID
      this.$http
        .get(
          process.env.VUE_APP_API_ENDPOINT + "/manager/getAssetsWithInstances",
          { headers }
        )
        .then((response) => {
          // handle success
          //console.log(response.data);
          this.assets = response.data;
          this.loadingAssets = false;
        })
        .catch((error) => {
          // handle error
          console.log(error);
        })
        .then(function () {
          // always executed
        });
    },

    assetModified(response) {
      //console.log("Update asset done");
      this.success = response.data;
      this.successBar = true;

      // Reload the assets updated
      this.fetchAssets();
    },

    instanceModified(response) {
      //console.log("Update instances done");
      this.success = response.data;
      this.successBar = true;

      // Reload the assets updated
      this.fetchAssets();
    },

    instanceDeleted(response) {
      //console.log("Delte instance done");
      this.success = response.data;
      this.successBar = true;

      // Reload the assets updated
      this.fetchAssets();
    },

    instancesAdded(response) {
      //console.log("New Instances added..." + response);
      this.success = response.data;
      this.successBar = true;

      // Reload the assets updated
      this.fetchAssets();

      // Re-evaluate if assets are deletable
      //this.$refs.modifyInstanceFormRef.checkIfDeletable();
      //console.log('Verify that new instance is deletable!')
    },

    returnFeedback(responseData) {
      this.success = responseData;
      this.successBar = true;
    },
  },

  mounted: function () {
    this.fetchAssets();
  },
};
</script>