<template>
  <v-flex xs12 md8>
    <v-stepper v-model="step" vertical>

    <v-stepper-step step="1" :complete="step > 1">
      Asset info
      <small>Alert message</small>
    </v-stepper-step>
    <v-stepper-content step="1">
      <v-container>
        <v-text-field ref="name_field" label="Name" :counter="max_title" v-model="asset.name" required :rules="name_rule" hide-details="auto" placeholder="Add the asset name" outlined></v-text-field>
      </v-container>
      <v-container>
        <v-textarea ref="desc_field" label="Description" :counter="max_description" v-model="asset.description"  required :rules="description_rule" hide-details="auto" placeholder="Add the asset description" outlined></v-textarea>
      </v-container>
      <v-container>
        <v-text-field v-model="asset.producerUrl" label="Producer Url" placeholder="Add the asset producer url" outlined></v-text-field>
      </v-container>
      <v-container>
        <Tags @tags-update="rebuildTags"></Tags>
      </v-container>
      <v-container>
          <v-checkbox label="Transferable" v-model="asset.transferable"></v-checkbox>
      </v-container>
      <v-container>
          <v-checkbox label="Setup required" v-model="asset.setup_required"></v-checkbox>
      </v-container>         
        <v-btn color="primary" @click.native="step = 2">Continue</v-btn>
    </v-stepper-content>

    <v-stepper-step step="2" :complete="step > 2">Asset images</v-stepper-step>
    <v-stepper-content step="2">
      <v-container>
        <v-file-input
          v-model="image"
          label="Asset image to upload"
          filled
          prepend-icon="mdi-camera"
        ></v-file-input>
      </v-container>
      <v-btn text @click.native="step = 1">Previous</v-btn>
      <v-btn color="primary" @click.native="step = 3">Continue</v-btn>
    </v-stepper-content>

    <v-stepper-step step="3">Asset instances</v-stepper-step>
    <v-stepper-content step="3">
      <v-text-field label="Number of Instances" type="number" v-model="asset.n_of_instances" @blur="rebuildInstances" @change="rebuildInstances" required></v-text-field>
      <v-container v-if="!asset.transferable">
        <AssetInstance v-for="n in Number(asset.n_of_instances)"
          :key="n" :name="asset.name" :id="n-1" :transferable="asset.transferable" :setup_required="asset.setup_required" @instanceUpdated="updateInstance">
        </AssetInstance>
      </v-container>
      <v-container v-else>
        <v-text-field v-model="instance_custom_name" label="Custom instance name" :placeholder="asset.name" @change="rebuildInstances" outlined></v-text-field>
      </v-container>

      
      <v-btn text @click.native="step = 2">Previous</v-btn>
      <v-btn color="primary" @click.prevent="submit">Create Asset</v-btn>
      </v-stepper-content>
    </v-stepper>
  </v-flex>
  
</template>

<script>
  
  import Tags from './Tags'
  import AssetInstance from './AssetInstance'

  export default {
    
    name: 'AddAsset',

    components: {
      Tags,
      AssetInstance

    },

    data: () => ({
      step:1,
      max_title: 30,
      max_description: 250,
      asset:{
        name: null,
        description: null,
        producerUrl: null,
        //image: null,
        tags: [],
        transferable: false,
        setup_required: false,
        n_of_instances: 0,
        instances:[]  // for each instance the dip_id (id assigned by our Dept.) could be autofilled or defined and the bool that specify if this instance is transferable or not 
      },
      image: null,
      instance_custom_name: "",
      name_rule: [
        value => !!value || 'Required.',
        value => (value && value.length >= 3 && value.length <= 30) || 'Min 3 and Max 30 characters',
      ],
      description_rule: [
        value => !!value || 'Required.',
        value => (value && value.length >= 3 && value.length <= 250)  || 'Min 3 and Max 250 characters',
      ],
    }),

    methods: {

      rebuildTags(value) {
        //console.log(value);
        this.tags = value;
      },

      // As soon the number of instances is set or custom instance name is set then rebuild the instances array
      // If the asset is trasferable no need to set a specific dip_id, we use the autogen one
      rebuildInstances() {
        //console.log('rebuilding instances...');
        // Reset the instances
        this.asset.instances = []
        let instance_name = "";

        if(!this.instance_custom_name.trim() == "") {
          instance_name = this.instance_custom_name;
        }
        else {
          instance_name = this.asset.name;
        }
        for(let i=0; i < this.asset.n_of_instances; i++){
          let instance = {
            dip_id: instance_name + '_' + i,
            creation_date: Date.now(),
            transferable: this.asset.transferable,
            setup_required: this.asset.setup_required,
          }
          this.asset.instances.push(instance)
        }
        //console.log(this.asset.instances)
      },

      // In case the asset is not transferable we update each modified instance into the array
      // when one of the instance dip_id or transferable attribute is changed into the AssetInstance component we trigger this event
      updateInstance(newVal) {
        // Modify the values of this instance into the array
        //console.log(newVal);
        //console.log(newVal.index);

        if(newVal.dip_id != ""){
          this.asset.instances[newVal.index].dip_id = newVal.dip_id;
        }
        this.asset.instances[newVal.index].creation_date = newVal.creation_date;
        this.asset.instances[newVal.index].transferable = newVal.transferable;
        this.asset.instances[newVal.index].setup_required = newVal.setup_required;
      },

      // Post to server API
      submit() {

        // Before send the asset we add the 'all' tag and convert all the tags to lowercase
        // Set all the tags lowercase to avoid case sensitive problems
        var tagsLowercase = this.tags.map(function(v) {
            return v.toLowerCase();
        });

        if(!tagsLowercase.includes("#all")){
          tagsLowercase.push("#all");
        }
        
        // Reassing the lowercase tags:
        this.asset.tags = tagsLowercase;

        //console.log(this.asset.tags);

        // Prepare multipart message
        const formdata = new FormData();

        formdata.append('auth_token', this.$store.getters.auth_jwt);
        formdata.append('asset', JSON.stringify(this.asset));
        formdata.append('image', this.image);

        //console.log(formdata);


        const headers = {
          'Authorization': 'Bearer ' + this.$store.getters.auth_jwt
        };

        this.$http.post(process.env.VUE_APP_API_ENDPOINT +'/Manager/addAsset', formdata, {headers})
        .then(response => {
            //console.log(response);
            if(response.status === 200){
              //console.log("Asset created");
              //console.log(response.data)
              this.$router.push('show_assets');
            }
        })
        .catch(err => {
          console.log('Error happened...');
          console.log(err.response.data);
          console.log(typeof(err))
          console.log(err)
        });

      },
    }

  }

</script>