<template>
  <v-item>
    <v-lazy
      v-model="isActive"
      :options="{ threshold: 0.5 }"
      min-height="200"
      width="250"
      transition="fade-transition"
    >
      <v-card class="mx-1">
        <v-badge
          :color="
            this.asset.nOfBorrowable > (this.asset.nOfAvailableInstances / 100 * 25) && this.asset.nOfBorrowable > 1
              ? 'green'
              : this.asset.nOfBorrowable >= 1
              ? 'orange'
              : 'red'
          "
          :content="this.asset.nOfBorrowable + '/' + this.asset.nOfAvailableInstances"
          overlap
        >
          <v-img
            class="mx-0 zoom"
            v-if="this.asset.thumbnail"
            :lazy-src="apiEndpoint + '/images/thumbnails/placeholder.png'"
            :src="apiEndpoint + '/' + this.asset.thumbnail"
            width="100"
            @click="openImage"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-badge>

        <ReservationForm
          v-if="this.asset.nOfBorrowable > 0"
          :asset="this.asset"
          :maxCount="this.asset.nOfBorrowable"
          @close="$emit('close', arguments[0])"
        >
        </ReservationForm>
        <v-card-title>
          {{ this.asset.name }}
        </v-card-title>
        <v-card-text>
          <v-row>
            {{ this.asset.description }}
          </v-row>
          <v-row>
            <v-btn v-if="asset.url" @click="goto" color="primary" text>
              More Info
            </v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-lazy>
  </v-item>
</template>


<script>
import ReservationForm from "./ReservationForm";

export default {
  name: "AssetsPreview",

  props: {
    asset: Object,
  },

  data: () => ({
    isActive: false,
    apiEndpoint: process.env.VUE_APP_API_ENDPOINT,
  }),

  components: {
    ReservationForm,
  },

  methods: {
    goto: function () {
      window.open(this.asset.url, "_blank");
    },

    openImage() {

      this.$emit('openImage', this.asset)
    }
  },
};
</script>

<style scoped>
.v-card__text,
.v-card__title {
  word-break: normal; /* maybe !important  */
}

.zoom {
  cursor: zoom-in !important;
}
</style>