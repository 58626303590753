<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="headline">Select User or Instance history:</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-container class="px-0" fluid>
            <v-switch
              v-model="searchUser"
              :label="'User history'"
              @change="
                searchInstance = !searchUser;
                resetInputs();
              "
            ></v-switch>
            <v-switch
              v-model="searchInstance"
              :label="'Instance history'"
              @change="
                searchUser = !searchInstance;
                resetInputs();
              "
            ></v-switch>
          </v-container>
          <v-row v-if="searchUser">
            <v-col class="d-flex" cols="12" sm="6">
              <v-select
                v-model="selectedUser"
                :items="users"
                item-text="fullname"
                item-value="_id"
                label="Select the user"
                @change="fetchUserEvents"
                return-object
                dense
              ></v-select>
            </v-col>
          </v-row>
          <div v-else>
            <v-row>
              <v-col class="d-flex" cols="12" sm="6">
                <v-select
                  v-model="selectedAsset"
                  :items="assets"
                  item-text="name"
                  item-value="_id"
                  @change="selectAsset"
                  label="Select the asset"
                  return-object
                  dense
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex" cols="12" sm="6">
                <v-select
                  v-model="selectedInstance"
                  :items="instances"
                  item-text="dip_id"
                  item-value="_id"
                  @change="fetchInstanceEvents"
                  label="Select the instance"
                  return-object
                  dense
                ></v-select>
              </v-col>
            </v-row>
          </div>
        </v-container>
      </v-card-text>
    </v-card>
    <v-flex xs12 md8>
      <v-timeline dense >
        <UserEventItem
          v-for="event in userEvents"
          :key="event._id"
          :event="event"
        ></UserEventItem>

        <InstanceEventItem
          v-for="event in instanceEvents"
          :key="event._id"
          :asset="selectedAsset"
          :assetInstance="selectedInstance"
          :event="event"
        ></InstanceEventItem>
      </v-timeline>
    </v-flex>
  </div>
</template>

<script>
//import ... from "./";
import UserEventItem from "./UserEventItem";
import InstanceEventItem from "./InstanceEventItem";

export default {
  name: "ManagerHistory",

  components: {
    UserEventItem,
    InstanceEventItem,
  },

  props: {},

  data: () => ({
    searchUser: true,
    searchInstance: false,
    users: [],
    assets: [],
    instances: [],
    selectedUser: null,
    selectedAsset: null,
    selectedInstance: null,

    userEvents: [],
    instanceEvents: [],
  }),

  methods: {
    // Reset input fields every important change
    resetInputs() {
      this.selectedUser = null;
      this.selectedAsset = null;
      this.selectedInstance = null;

      this.userEvents = [];
      this.instanceEvents = [];
    },

    fetchUsers() {
      // Post request to retrieve the user array
      //console.log("retrieving all the user that login at least once...");

      const headers = {
        Authorization: "Bearer " + this.$store.getters.auth_jwt,
      };

      // Make a request for a user with a given ID
      this.$http
        .get(process.env.VUE_APP_API_ENDPOINT + "/admin/getUsers", { headers })
        .then((response) => {
          // handle success
          //console.log(response.data);
          this.users = response.data;

          // Generate a new property to be able to visualize second_name and first_name into the ui list
          for (let u of this.users){
            u.fullname = u.second_name + " " + u.first_name;
          } 

          // sort by fullname
          this.users.sort((a, b) => a.fullname > b.fullname && 1 || -1)
        })
        .catch((error) => {
          // handle error
          console.log(error);
        })
        .then(function () {
          // always executed
        });
    },

    fetchAssets() {
      // Post request to retrieve the assets array
      //console.log("retrieving available assets...");

      const headers = {
        Authorization: "Bearer " + this.$store.getters.auth_jwt,
      };

      // Make a request for a user with a given ID
      this.$http
        .get(process.env.VUE_APP_API_ENDPOINT + "/common/assets", { headers })
        .then((response) => {
          this.assets = response.data;

          // sort by name
          this.assets.sort((a, b) => a.name > b.name && 1 || -1)
        })
        .catch((error) => {
          // handle error
          console.log(error);
        })
        .then(function () {
          // always executed
        });
    },

    selectAsset() {
      //console.log(this.selectedAsset.name);
      //console.log(this.selectedAsset._id);
      this.fetchInstances(this.selectedAsset._id);
    },

    // Retrieve assetInstace available
    fetchInstances(assetId) {
      // Used to update the list of available instances
      //console.log("retrieving all asset instances...");

      const headers = {
        Authorization: "Bearer " + this.$store.getters.auth_jwt,
      };

      // Make a request for available instances of an asset
      this.$http
        .get(
          process.env.VUE_APP_API_ENDPOINT + "/manager/getInstances/" + assetId,
          { headers }
        )
        .then((response) => {
          // handle success
          //console.log(response.data);
          this.instances = response.data;
        })
        .catch((error) => {
          // handle error
          console.log(error);
        })
        .then(function () {
          // always executed
        });
    },

    fetchUserEvents() {
      // Post request to retrieve the user array
      //console.log("retrieving all the user events");

      const headers = {
        Authorization: "Bearer " + this.$store.getters.auth_jwt,
      };

      // Make a request for a user with a given ID
      this.$http
        .get(
          process.env.VUE_APP_API_ENDPOINT +
            "/manager/getUserHistoryEvents/" +
            this.selectedUser._id,
          {
            headers,
          }
        )
        .then((response) => {
          // handle success
          //console.log(response.data);
          this.userEvents = response.data;
        })
        .catch((error) => {
          // handle error
          console.log(error);
        })
        .then(function () {
          // always executed
        });
    },

    fetchInstanceEvents() {
      // Post request to retrieve the user array
      //console.log("retrieving all the instance events");

      const headers = {
        Authorization: "Bearer " + this.$store.getters.auth_jwt,
      };

      // Make a request for a user with a given ID
      this.$http
        .get(
          process.env.VUE_APP_API_ENDPOINT +
            "/manager/getInstanceHistoryEvents/" +
            this.selectedInstance._id,
          {
            headers,
          }
        )
        .then((response) => {
          // handle success
          //console.log(response.data);
          this.instanceEvents = response.data;
        })
        .catch((error) => {
          // handle error
          console.log(error);
        })
        .then(function () {
          // always executed
        });
    },
  },

  created: function () {
    this.fetchUsers();
    this.fetchAssets();
  },
};
</script>