<template>
  <div>
    <v-card>
      <v-card-title>
        Active loans:
        <v-spacer></v-spacer>
        <v-text-field v-if="dtByUsers == 'user'" v-model="searchUser" append-icon="mdi-magnify" label="Search"
          single-line hide-details>
        </v-text-field>
        <v-text-field v-else v-model="searchAsset" append-icon="mdi-magnify" label="Search" single-line hide-details>
        </v-text-field>
        <AddLoanForm v-if="$store.getters.userRole == 'Admin'" @loanAdded="LoanAdded"></AddLoanForm>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-snackbar v-model="alertVisible" :timeout="3000" color="success">
        <strong>{{ changeStateSuccess }} </strong>
      </v-snackbar>

      <v-container class="no-print">
        <v-radio-group v-model="dtByUsers" mandatory row>
          <v-radio label="Show By User" value="user"></v-radio>
          <v-radio label="Show by Assets" value="asset"></v-radio>
        </v-radio-group>
      </v-container>

      <v-data-table v-if="dtByUsers == 'user'" :headers="headers_user" :items="users" :search="searchUser"
        class="elevation-1" show-expand :single-expand="true" item-key="username" :loading="loadingUsers"
        loading-text="Loading... Please wait" no-data-text="No loans found." :sort-by.sync="userSortBy"
        :sort-desc.sync="sortDesc">
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <tr>
              <HandleLoanForm v-for="assetInstance in item.loan" :key="assetInstance._id" :user="item"
                :assetInstance="assetInstance" @instanceUpdated="fetchAll" @returnDone="returnFeedback"
                @close="$emit('close')">
              </HandleLoanForm>
            </tr>
          </td>
        </template>
      </v-data-table>

      <v-data-table v-else :headers="headers_asset" :items="assets" :search="searchAsset" class="elevation-1"
        show-expand :single-expand="true" item-key="_id" :loading="loadingAsset" loading-text="Loading... Please wait"
        no-data-text="No loans found." :sort-by.sync="assetSortBy" :sort-desc.sync="sortDesc">
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <tr>
              <HandleLoanFormByAsset v-for="assetInstance in item.borrowedInstances" :key="assetInstance._id"
                :user="assetInstance.borrower" :assetInstance="assetInstance" @instanceUpdated="fetchAll"
                @returnDone="returnFeedback" @close="$emit('close')">
              </HandleLoanFormByAsset>
            </tr>
          </td>
        </template>
      </v-data-table>

    </v-card>
    <v-snackbar v-model="successBar" :timeout="5000" color="success">
      <strong>{{ success }} </strong>
    </v-snackbar>
    <v-snackbar v-model="errorBar" :timeout="5000" color="red">
      <strong>{{ error }} </strong>
    </v-snackbar>
  </div>
</template>


<script>
import HandleLoanForm from "./HandleLoanForm";
import HandleLoanFormByAsset from "./HandleLoanFormByAsset";
import AddLoanForm from "./AddLoanForm";

export default {
  name: "HandleLoans",

  data: () => ({
    loadingUsers: true,
    loadingAsset: true,
    dtByUsers: true, // choose if the datatable is grouped by users or by asset
    users: [],
    assets: [],
    headers_user: [
      { text: "LastName", value: "second_name" },
      { text: "eMail", value: "email" },
      { text: "Role", value: "role" },
      { text: "Reserved", value: "cart.length" },
      { text: "Borrowed", value: "loan.length" },
      { text: "Owned", value: "owned.length" },
      { text: "Can Borrow", value: "can_borrow" },
    ],
    headers_asset: [
      { text: "Name", value: "name" },
      { text: "Tot. n. of instances", value: "nOfInstances" },
      { text: "n. of available", value: "nOfAvailable" },
      { text: "n. of reserved", value: "nOfReserved" },
      { text: "n. of borrowed", value: "nOfBorrowed" },
      { text: "n. of maintenance", value: "nOfMantainance" },
      { text: "n. of dismissed", value: "nOfDismissed" },
      { text: "n. of deleted", value: "nOfDeleted" },
      { text: "n. in unknown status", value: "nOfUnknow" },
    ],
    searchUser: "",
    searchAsset: "",
    userSortBy: "second_name",
    assetSortBy: "name",
    sortDesc: false,
    alertVisible: false,
    changeStateSuccess: "",

    successBar: false,
    success: "",
    errorBar: false,
    error: "",
  }),

  components: {
    HandleLoanForm,
    HandleLoanFormByAsset,
    AddLoanForm,
  },
  methods: {

    fetchAll() {

      this.users = [];
      this.assets = [];
      this.fetchLoansByUsers();
      this.fetchLoansByAsset();
    },

    fetchLoansByUsers() {
      // Post request to retrieve the user array
      //console.log("retrieving all the user with pending loans...");

      const headers = {
        Authorization: "Bearer " + this.$store.getters.auth_jwt,
      };

      // Make a request for a user with a given ID
      this.$http
        .get(process.env.VUE_APP_API_ENDPOINT + "/manager/getLoansByUser", {
          headers,
        })
        .then((response) => {
          // handle success
          //console.log(response.data);
          this.users = response.data;
          this.loadingUsers = false;
        })
        .catch((error) => {
          // handle error
          console.log(error.response.data);
        })
        .then(function () {
          // always executed
        });
    },

    fetchLoansByAsset() {
      // Post request to retrieve the user array
      //console.log("retrieving all the asset  instance loan");

      const headers = {
        Authorization: "Bearer " + this.$store.getters.auth_jwt,
      };

      // Make a request for a user with a given ID
      this.$http
        .get(process.env.VUE_APP_API_ENDPOINT + "/manager/getLoansByAsset", {
          headers,
        })
        .then((response) => {
          // handle success
          //console.log(response.data);

          //this.assets = response.data;
          for (let asset of response.data) {

            let nOfInstances = 0;
            let nOfBorrowed = 0;
            let nOfAvailable = 0;
            let nOfReserved = 0;
            let nOfMantainance = 0;
            let nOfDismissed = 0;
            let nOfDeleted = 0;
            let nOfUnknow = 0;

            let borrowedInstances = [];


            if (asset.instances.length > 0) {
              nOfInstances = asset.instances.length;
              
              for (let instance of asset.instances) {
                console.log(instance);
                if (instance.borrower && instance.status == "Borrowed") {
                  nOfBorrowed++;
                  borrowedInstances.push(instance);
                } else if (instance.status == "Available") {
                  nOfAvailable++;
                }
                else if (instance.status == "Reserved") {
                  nOfReserved++;
                }
                else if (instance.status == "Maintenance") {
                  nOfMantainance++;
                }
                else if (instance.status == "Dismissed") {
                  nOfDismissed++;
                }
                else if (instance.status == "Deleted") {
                  nOfDeleted++;
                }
                else {
                  nOfUnknow++;
                }
              }
              asset.nOfInstances = nOfInstances;
              asset.nOfBorrowed = nOfBorrowed;
              asset.nOfAvailable = nOfAvailable;
              asset.nOfReserved = nOfReserved;
              asset.nOfMantainance = nOfMantainance;
              asset.nOfDismissed = nOfDismissed;
              asset.nOfDeleted = nOfDeleted;
              asset.nOfUnknow = nOfUnknow;

              asset.borrowedInstances = borrowedInstances;


              this.assets.push(asset);
            }
          }

          this.loadingAsset = false;
        })
        .catch((error) => {
          // handle error
          console.log(error.response.data);
        })
        .then(function () {
          // always executed
        });
    },

    returnFeedback(responseData) {
      this.success = responseData;
      this.successBar = true;
    },

    LoanAdded(response) {
      this.success = response.data;
      this.successBar = true;

      // Reload the assets updated
      //this.fetchLoansByUsers();
      this.fetchAll();
    },
  },

  mounted: function () {
    this.fetchAll();
  },
};
</script>

<style scoped>
@media print {
  body {
    overflow: auto;
    height: auto;
  }

  .scroll-y {
    height: auto;
    overflow: visible;
  }

  .v-main {
    padding: 0 !important;
  }

  .v-content {
    padding: 0 !important;
  }

  .no-print {
    display: none;
    padding: 0 !important;

  }
}
</style>