<template>
  <v-dialog v-model="dialog" persistent max-width="500px">
    <template v-slot:activator="{ on, attrs }">
      <v-icon small class="mr-2" v-bind="attrs" v-on="on"> mdi-pencil </v-icon>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">Asset: {{ asset.name }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-container>
            <v-text-field
              ref="name_field"
              label="Name"
              :counter="max_title"
              v-model="asset.name"
              required
              :rules="name_rule"
              hide-details="auto"
              placeholder="Add the new asset name"
              outlined
            ></v-text-field>
          </v-container>
          <v-container>
            <v-textarea
              ref="desc_field"
              label="Description"
              :counter="max_description"
              v-model="asset.description"
              required
              :rules="description_rule"
              hide-details="auto"
              placeholder="Add the new asset description"
              outlined
            ></v-textarea>
          </v-container>
          <v-container>
            <v-text-field
              v-model="asset.url"
              label="Producer Url"
              placeholder="Add the new asset producer url"
              outlined
            ></v-text-field>
          </v-container>
          <v-container>
            <Tags :oldTags="asset.tags" @tags-update="rebuildTags"></Tags>
          </v-container>
        </v-container>
        <v-container>
        <v-file-input
          v-model="image"
          label="New Asset image to upload"
          filled
          prepend-icon="mdi-camera"
        ></v-file-input>
      </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="close"> Back </v-btn>
        <v-btn color="success" text @click="updateAsset"> Update </v-btn>
      </v-card-actions>
      <v-snackbar v-model="successBar" :timeout="5000" color="success">
        <strong>{{ success }} </strong>
      </v-snackbar>
      <v-snackbar v-model="errorBar" :timeout="5000" color="red">
        <strong>{{ error }} </strong>
      </v-snackbar>
    </v-card>
  </v-dialog>
</template>


<script>
import Tags from "./Tags";

export default {
  name: "ModifyAssetForm",

  components: {
    Tags,
  },

  props: {
    asset: Object,
  },

  data: () => ({
    dialog: false,
    successBar: false,
    success: "",
    errorBar: false,
    error: "",

    max_title: 30,
    max_description: 250,
    name_rule: [
      (value) => !!value || "Required.",
      (value) =>
        (value && value.length >= 3 && value.length <= 30) ||
        "Min 3 and Max 30 characters",
    ],
    description_rule: [
      (value) => !!value || "Required.",
      (value) =>
        (value && value.length >= 3 && value.length <= 250) ||
        "Min 3 and Max 250 characters",
    ],
    image: null,


  }),

  methods: {
    rebuildTags(value) {
    //console.log(value);
      // Before send the asset we add the 'all' tag if missing and convert all the tags to lowercase
      this.asset.tags = value;
    },

    updateAsset() {
      const headers = {
        Authorization: "jwt " + this.$store.getters.auth_jwt,
      };

      // Before build body to update asset we rebuild the tags
      // Before send the asset we add the 'all' tag if missing and convert all the tags to lowercase
      // Set all the tags lowercase to avoid case sensitive problems
      var tagsLowercase = this.asset.tags.map(function(v) {
          return v.toLowerCase();
      });

      if(!tagsLowercase.includes("#all")){
          tagsLowercase.push("#all");
      }
      //console.log(tagsLowercase);
      // Reassing the lowercase tags:
      this.asset.tags = tagsLowercase;


      // Prepare multipart message
      const formdata = new FormData();

      formdata.append('auth_token', this.$store.getters.auth_jwt);
      formdata.append('asset', JSON.stringify(this.asset));
      formdata.append('image', this.image);

      this.$http
        .put(
          process.env.VUE_APP_API_ENDPOINT + "/manager/modifyAsset",
          formdata,
          { headers }
        )
        .then((response) => {
          //console.log(response);
          if (response.status === 200) {
            this.$emit("assetModified", response);
            this.close();
          }
        })
        .catch((err) => {
          console.log("Error happened...");
          this.errorBar = true;
          this.error = err.response.data;
        });
    },

    close() {
      // Emit that the reservation form has closed, we are going to refresh the asset list
      this.$emit("close");
      this.errorBar = false;
      this.dialog = false;
    },
  },

  created: function () {
  },
};
</script>