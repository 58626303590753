const months = ['January','February','March','April','May','June','July','August','September','October','November','December'];
const days= ['Sun','Mon','Tue','Wed','Thu','Fri','Sat'];


export function BeautyDateTime(date) {

  //console.log(date);

    const year = date.getFullYear() // 2019
    const dayNumber = date.getDate() // 23
    const dayName = days[date.getDay()]
    const monthName = months[date.getMonth()]

    let raw_minutes = date.getMinutes();
    if(raw_minutes < 10){
      raw_minutes = '0' + raw_minutes
    }
    const formatted = dayName + ', ' + dayNumber + ' ' + monthName + ' ' + year + ' - ' + date.getHours() + ':' + raw_minutes;
    //const dateTime = today.toISOString();
    
    return formatted;
}

export function BeautyDate(date) {

  //console.log(date);

    const year = date.getFullYear() // 2019
    const dayNumber = date.getDate() // 23
    const dayName = days[date.getDay()]
    const monthName = months[date.getMonth()]

    const formatted = dayName + ', ' + dayNumber + ' ' + monthName + ' ' + year;
    
    return formatted;
}