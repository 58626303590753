<template>
    <v-item>
        <v-lazy
            v-model="isActive"
            :options="{ threshold: .5}"
            min-height="200"
            width= "250"
            transition="fade-transition"
        >
            <v-card class="mx-1" >
                <v-row class="ma-0" align="center" justify="center">
                    <v-img
                    class="mx-6"
                    v-if="this.assetInstance.asset.thumbnail"
                    :lazy-src="apiEndpoint +'/images/thumbnails/placeholder.png'"
                    :src="apiEndpoint +'/' + this.assetInstance.asset.thumbnail"
                    width="80"
                    >
                        <template v-slot:placeholder>
                            <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                            >
                            <v-progress-circular
                                indeterminate
                                color="grey lighten-5"
                            ></v-progress-circular>
                            </v-row>
                        </template>
                    </v-img>
                </v-row>       
                
                
                <v-card-title>
                    {{this.assetInstance.asset.name}}
                </v-card-title>
                <v-card-text>
                    {{this.assetInstance.asset.description}}
                </v-card-text>

                <v-card-text>
                   This item must be returned no later than: <strong> {{beautifyDate(this.assetInstance.due_back)}} </strong>
                </v-card-text>
            </v-card>
        </v-lazy>
    </v-item>
</template>


<script>

// My beauty date formatter
import {BeautyDate} from '../../utils/BeautyDate' // eslint-disable-line no-unused-vars

export default {
    
    name: 'BorrowedItemPreview',

    props: {
          assetInstance: Object,
    },

    data: () => ({

      isActive: false,
      apiEndpoint: process.env.VUE_APP_API_ENDPOINT,
    }),

    components: {
    },

    methods:  {
        
        beautifyDate(date) {
          if(date){
            return BeautyDate(new Date(date));
          }
          
        },
        
    }
}
</script>