import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({

    icons: {
        iconfont: 'mdi',
    },

    theme: {
        
        themes: {
            light: {
            primary: '#003366',
            secondary: '#707173',
            success: '#009933',
            error: '#b71c1c',
            background: '#707173',
            },
            dark: {
                primary: '#003366',
                secondary: '#707173',
                success: '#009933',
                error: '#b71c1c',
                background: '#707173',
                },
        },
    },

});
