<template>
  <v-combobox
    v-model="chips"
    :items="items"
    chips
    clearable
    label="Asset tags"
    multiple
    placeholder="Add the asset tags"
    outlined
    v-on:input="$emit('tags-update', $event)"
  >
    <template v-slot:selection="{ attrs, item, select, selected }">
      <v-chip
        v-bind="attrs"
        :input-value="selected"
        close
        @click="select"
        @click:close="remove(item)"
      >
        <strong>{{ item }}</strong>&nbsp;
      </v-chip>
    </template>
  </v-combobox>
</template>

<script>
  export default {

    props: {
      oldTags: Array,
    },
    data () {
      return {
        chips: [],
        items: ['Laptop', 'Desktop', 'Electronics', 'Accessories', 'Memory', 'Cable', 'Usb' ],
      }
    },

    methods: {
      remove (item) {
        this.chips.splice(this.chips.indexOf(item), 1)
        this.chips = [...this.chips]
        this.$emit('tags-update', this.chips)
        //console.log(this.chips)
      }
    },

    created: function () {
    this.chips = this.oldTags;
  },
  }
</script>