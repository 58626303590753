<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" elevation="3" fab small v-bind="attrs" v-on="on">
            <v-icon>add_shopping_cart</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="headline">{{this.asset.name}}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form v-model="valid">
              <v-row >   
                <v-col
                  cols="12"
                  sm="12"
                  md="6"
                >
                  <v-text-field label="Quantity" type="number"
                      v-model="form.nOf"
                      hint="Specify the quantity"
                      persistent-hint
                      required
                  ></v-text-field>
                </v-col>              
              </v-row>
              <v-row >
                <v-col cols="12" sm="12">
                  <v-slider
                      v-model="form.nOf"
                      :color="(form.nOf < (maxCount/2)) ? 'green' : 'orange'"
                      label=""
                      hint="The number of assets you want to reserve"
                      min="1"
                      :max="maxCount"
                      thumb-label
                  ></v-slider>
                </v-col>
              </v-row>

              <v-row justify="center">
                <v-col cols="12" sm="12">
                  <v-menu
                    ref="menu1"
                    v-model="dateMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dateFormatted"
                        label="Return Date"
                        persistent-hint
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        :clearable="true"
                        :readonly="true"
                        required
                        :rules="date_rule"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="form.dueBack"
                      no-title
                      @input="dateMenu = false; dateFormatted = beautifyDate(form.dueBack)"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" sm="12">
                    <v-textarea label="Reason & Requests" :rules="motivation_rule" :counter="250" v-model="form.note"  required hide-details="auto" placeholder="Add the reason for the loan and any request" outlined></v-textarea>
                </v-col>
              </v-row>
            </v-form>

          </v-container>
          
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="cancel"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="makeReservation"
            :disabled="!valid"
          >
            Make Reservation
          </v-btn>
        </v-card-actions>
        <v-snackbar v-model="reservationError" :timeout="3000" color="red">
          <strong>{{ error }} </strong>
        </v-snackbar>
      </v-card>
    </v-dialog>
  </v-row>
</template>


<script>

  // My beauty date formatter
  import {BeautyDate} from '../../utils/BeautyDate' // eslint-disable-line no-unused-vars

  export default {

    name: 'ReservationForm',

    props: {
        asset: Object,
        maxCount: Number,
    },

    data: () => ({
        dialog: false,
        form: {
            nOf: 0,
            dueBack: null,
            note: ""
        },
        dateMenu:false,
        dateFormatted: null,
        reservationStatus: false,
        reservationError: false,
        error: "",
        valid: false,
        max_description: 250,
        motivation_rule: [
          value => !!value || 'Required.',
          value => (value && value.length >= 5 && value.length <= 250) || 'Min 5 and Max 250 characters',
        ],
        date_rule: [
          value => !!value || 'Required.',
        ],
    }),

    methods : {

        beautifyDate(date) {

          //console.log(this.form.dueBack)
          if(date){
            return BeautyDate(new Date(date));
          }
        },

        makeReservation() {

            // Prepare the message
            const headers = {
            'Authorization': 'jwt ' + this.$store.getters.auth_jwt
            };

            const body = {

                // Asset id (What)
                "asset": this.asset._id,

                // Number of asset instance to reserve (How many)
                "nOf": this.form.nOf,

                // The user want to give this item back on this date
                "desired_due_back": this.form.dueBack,

                // Specify any request
                "note": this.form.note,
            }

            this.$http.post(process.env.VUE_APP_API_ENDPOINT +'/common/reserveAssets', body, {headers})
            .then(response => {
                //console.log(response);
                if(response.status === 200){

                    //console.log("Assets reserved");
                    //console.log(response.data);
                    
                    // Save the user cart into vuex status
                    this.$store.commit('setCart', response.data);
                    //console.log(this.$store.getters.cart);
                    this.reservationStatus = true;
                    this.close();

                }
            })
            .catch(err => {
                console.log('Error happened...');
                this.reservationError = true
                this.error = err.response.data
            });
        },

        cancel() {

          this.reservationStatus = false;
          this.close()
        },

        close() {
            // Emit that the reservation form has closed, we are going to refresh the asset list
            this.$emit('close', this.reservationStatus)
            this.reservationError = false;
            this.dialog = false;
        }
    }
  }
</script>