import Vue from 'vue';
import App from './App.vue';

import Vuex from 'vuex';
Vue.use(Vuex)

import VueRouter from 'vue-router';
Vue.use(VueRouter);

// for http

//import VueResource from 'vue-resource';
//Vue.use(VueResource);
import axios from 'axios'

Vue.prototype.$http = axios

import vuetify from './plugins/vuetify';
import 'material-design-icons-iconfont/dist/material-design-icons.css';


Vue.config.productionTip = false;

const store  = new Vuex.Store({
  state() {
    return {
      valid_jwt: false,
      auth_jwt: '',
      initials:'',
      user_role: '',
      cart: [],
      loan: [],
      loan_history: [],
      owned: [],
      notifications: [],
    };
  },
  
  mutations: {

    setValidJWT(state, valid){
      
      state.valid_jwt = valid;
    },

    setAuthJWT(state, jwt){

      // Set the obtained auth jwt
      state.auth_jwt = jwt;

      // Store it also to the local storage (to maintain a valid session even if page reloaded)
      localStorage.setItem('auth_token', jwt)

    },

    setInitials(state,initials) {

      state.initials = initials;
    },

    setUserRole(state,role) {
      
      state.user_role = role;
    },

    setCart(state,cart) {
      
      state.cart = cart;
    },

    setLoan(state,loan) {
      
      state.loan = loan;
    },

    setLoanHistory(state,history) {
      
      state.loan_history = history;
    },

    setOwned(state,owned) {
      
      state.owned = owned;
    },

    setNotifications(state,notifications) {

      state.notifications = notifications;
    }

  },

  getters: {

    isAuth(state) {

      return state.valid_jwt
    },

    auth_jwt(state) {
      
      // We can process or check validity here if needed
      return state.auth_jwt;
    },

    initials(state){

      return state.initials;
    },

    userRole(state){

      if (state.user_role == 'prestito_admin') {
        return 'Admin';
      }
      else if(state.user_role == 'prestito_manager') {
        return 'Manager';
      }
      else if(state.user_role == 'prestito_auditor') {
        return 'Auditor';
      }
      else if(state.user_role == 'prestito_user') {
        return 'User';
      }     
      else if(state.user_role == 'prestito_viewer') {
        return 'View-mode only';
      }
    },

    cart(state){
      
      return state.cart;
    },

    loan(state){
      
      return state.loan;
    },

    loan_history(state){
      
      return state.loan_history;
    },

    owned(state){
      
      return state.owned;
    },

    notifications(state) {

      return state.notifications;
    }
  }
});


// _____________________________
// Configure the router
// import various components

// Common routes
import ShowAssets from './components/common/ShowAssets'
import Cart from './components/common/Cart'
import Notifications from './components/common/Notifications'
import Borrowed from './components/common/Borrowed'
import UserHistory from './components/common/UserHistory'
import Contactus from './components/common/Contactus'

// Auditor routes
import ViewLoans from './components/auditor/ViewLoans'

// Manager routes
import AddAsset from './components/manager/AddAsset'
import ModifyAssets from './components/manager/ModifyAssets'
import HandleReservations from './components/manager/HandleReservations'
import HandleLoans from './components/manager/HandleLoans'
import ManagerHistory from './components/manager/history/ManagerHistory'

// Admin routes
import ModifyUsers from './components/admin/ModifyUsers'
import AdminHistory from './components/admin/history/AdminHistory'
import Statistics from './components/admin/Statistics'

// Define the routes
const routes = [

  // Common routes
  { path: '/show_assets', component: ShowAssets},
  { path: '/cart', component: Cart},
  { path: '/borrowed', component: Borrowed},
  { path: '/user_history', component: UserHistory},
  { path: '/notifications', component: Notifications},
  { path: '/contactus', component: Contactus},

  // Auditor routes
  { path: '/user_list', component: ViewLoans},

  // Manager routes
  { path: '/add_asset', component: AddAsset},
  { path: '/modify_assets', component: ModifyAssets},
  { path: '/manage_reservations', component: HandleReservations},
  { path: '/manage_loans', component: HandleLoans},
  { path: '/manager_history', component: ManagerHistory},



  // Admin routes 
  { path: '/modify_user', component: ModifyUsers},
  { path: '/admin_history', component: AdminHistory},
  { path: '/statistics', component: Statistics},
]

// Create the router passing the routes
const router = new VueRouter({

  mode: 'history',
  routes: routes
});
// _____________________________


new Vue({
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app');
