<template>
    <div>
        <v-card>
            <v-card-title>
            Users with pending reservations:
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
            ></v-text-field>
            </v-card-title>
            <v-snackbar v-model="alertVisible" :timeout="3000" color="success">
            <strong>{{ changeStateSuccess }} </strong>
            </v-snackbar>
            <v-data-table
                :headers="headers"
                :items="users"
                :search="search"
                class="elevation-1"
                show-expand
                :single-expand="true"
                item-key="username"
                :loading="loadingUsers"
                loading-text="Loading... Please wait"
                no-data-text="No pending reservations found! Good job ;)"
                >

                <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length">
                        <tr >
                            <HandleReservationForm 
                                v-for="assetInstance in item.cart"
                                :key="assetInstance._id"
                                :user="item"
                                :assetInstance="assetInstance" 
                                @instanceUpdated="fetchUsers"
                                @loanDone="loanFeedback"
                                @close="$emit('close')"
                                >
                            </HandleReservationForm>
                        </tr>
                    </td>
                </template>
            </v-data-table>
        </v-card>
        
        <v-snackbar v-model="successBar" :timeout="5000" color="success">
            <strong>{{ success }} </strong>
        </v-snackbar>
        <v-snackbar v-model="errorBar" :timeout="5000" color="red">
            <strong>{{ error }} </strong>
        </v-snackbar>
    </div>
</template>


<script>

import HandleReservationForm from './HandleReservationForm'

export default {
    
    name: 'HandleReservations',

    data: () => ({
        loadingUsers: true,
        users: [],
        headers: [
          {text: 'Username', value: 'username',
          },
          { text: 'eMail', value: 'email' },
          { text: 'Role', value: 'role' },
          { text: 'Reserved', value: 'cart.length' },
          { text: 'Borrowed', value: 'loan.length' },
          { text: 'Owned', value: 'owned.length' },
          { text: 'Can Borrow', value: 'can_borrow' }
        ],
        search: "",
        alertVisible: false,
        changeStateSuccess: "",

        successBar: false,
        success: "",
        errorBar: false,
        error: "",


    }),

    components:{
        HandleReservationForm,
    },
    methods: {

        fetchUsers() {
            // Post request to retrieve the user array
            //console.log('retrieving all the user with pending reservations...');

            const headers = {
                'Authorization': 'Bearer ' + this.$store.getters.auth_jwt
            };

            // Make a request for a user with a given ID
            this.$http.get(process.env.VUE_APP_API_ENDPOINT +'/manager/getReservations', {headers})
            .then(response => {
                // handle success
                //console.log(response.data);
                this.users = response.data;
                this.loadingUsers=false;
            })
            .catch(error => {
                // handle error
                console.log(error.response.data);
            })
            .then(function () {
                // always executed
            });
        },

        loanFeedback(responseData) {
            this.success = responseData;
            this.successBar = true;
        },

        // handleReservation(user, assetInstance) {

        //     console.log(user._id + ' -> ' +user.username + ' $ ' + assetInstance._id + ' -> ' + assetInstance.asset.name);
        // }
    },

    mounted: function() {
        this.fetchUsers();
    }
}
</script>