<template>
  <div>
    <v-card>
      <v-card-title>
        Prestito users:
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
        <AddUserForm @userAdded="UserAdded"> </AddUserForm>
      </v-card-title>
      <v-snackbar v-model="alertVisible" :timeout="3000" color="success">
        <strong>{{ changeStateSuccess }} </strong>
      </v-snackbar>
      <v-data-table
        :headers="headers"
        :items="users"
        :search="search"
        class="elevation-1"
        :loading="loadingUsers"
        loading-text="Loading... Please wait"
      >
        <template v-slot:[`item.can_borrow`]="{ item }">
          <v-simple-checkbox
            :ripple="false"
            @input="switchBorrowAuth(item)"
            v-model="item.can_borrow"
          ></v-simple-checkbox>
        </template>
      </v-data-table>
    </v-card>
    <v-snackbar v-model="successBar" :timeout="5000" color="success">
      <strong>{{ success }} </strong>
    </v-snackbar>
    <v-snackbar v-model="errorBar" :timeout="5000" color="red">
      <strong>{{ error }} </strong>
    </v-snackbar>
  </div>
</template>


<script>
import AddUserForm from "./AddUserForm";
export default {
  name: "ModifyUsers",

  components: {
    AddUserForm,
  },

  data: () => ({
    loadingUsers: true,
    users: [],
    headers: [
      { text: "Last Name", value: "second_name" },
      { text: "eMail", value: "email" },
      { text: "Role", value: "role" },
      { text: "Reserved", value: "cart.length" },
      { text: "Borrowed", value: "loan.length" },
      { text: "Owned", value: "owned.length" },
      { text: "Can Borrow", value: "can_borrow" },
    ],
    search: "",
    alertVisible: false,
    changeStateSuccess: "",

    successBar: false,
    success: "",
    errorBar: false,
    error: "",
  }),

  methods: {
    fetchUsers() {
      // Post request to retrieve the user array
      //console.log("retrieving all the user that login at least once...");

      const headers = {
        Authorization: "Bearer " + this.$store.getters.auth_jwt,
      };

      // Make a request for a user with a given ID
      this.$http
        .get(process.env.VUE_APP_API_ENDPOINT + "/admin/getUsers", { headers })
        .then((response) => {
          // handle success
          //console.log(response.data);
          this.users = response.data;
          this.loadingUsers = false;
        })
        .catch((error) => {
          // handle error
          console.log(error.response.data);
        })
        .then(function () {
          // always executed
        });
    },

    switchBorrowAuth(item) {
      //console.log(item);
      // Prepare the message
      const headers = {
        Authorization: "jwt " + this.$store.getters.auth_jwt,
      };

      const body = {
        // User id (Who)
        userId: item._id,

        // Username
        username: item.username,

        can_borrow: item.can_borrow,
      };

      this.$http
        .put(
          process.env.VUE_APP_API_ENDPOINT + "/admin/setUserBorrowState",
          body,
          { headers }
        )
        .then((response) => {
          //console.log(response);
          if (response.status === 200) {
            //console.log(response.data);
            this.changeStateSuccess = response.data;
            this.alertVisible = true;
            //console.log(response.data);
            setTimeout(() => {
              this.alertVisible = false;
            }, 3000);
          }
        })
        .catch((err) => {
          console.log(
            "Error happened during user " +
              item.username +
              " borrow state change..." +
              err
          );
        });
    },

    UserAdded(response) {
      this.success = response.data;
      this.successBar = true;

      // Reload the assets updated
      this.fetchUsers();
    },
  },

  mounted: function () {
    this.fetchUsers();
  },
};
</script>