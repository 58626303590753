<template>
    <v-container>
        <h1>
            List of notifications:
        </h1>
        
        <v-row>
            <v-switch
            class="ma-4"
            v-model="showUnread"
            label="Show unread notifications"
            @click="displayAllNotifications(); showDeleted=false"
            ></v-switch>
            <v-switch
                class="ma-4"
                v-model="showDeleted"
                label="Show deleted notifications"
                @click="displayAllNotifications(); showUnread=false"
            ></v-switch>
        </v-row>

        <v-row v-if="notifications.length == 0" class="ma-6">
            <h4>
                (No new notifications)
            </h4>
        </v-row>
        
        <v-expansion-panels v-model="panel">
            <v-expansion-panel v-for="n of notifications" :key="n._id" @click="changeNotificationState(n, 'Read')" >
                
                <div v-if="n.status === 'Deleted'"> 
                    <v-expansion-panel-header disable-icon-rotate color="blue-grey">
                    <strong> {{n.title}} </strong> ( {{beautifyDate(n.creation_date)}} )
                    <template v-slot:actions>
                        <v-icon color="error">
                            mdi-alert-circle
                        </v-icon>
                        <v-icon color="primary">
                            mdi-trash-can
                        </v-icon>
                    </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>

                    <div v-html="n.message"></div>

                    </v-expansion-panel-content>
                </div>

                <div v-else> 
                    <v-expansion-panel-header disable-icon-rotate>
                    <strong> {{n.title}} </strong> ( {{beautifyDate(n.creation_date)}} )
                    <template v-slot:actions>
                        <v-icon color="error">
                            mdi-alert-circle
                        </v-icon>
                        <v-icon color="primary" v-if="n.status === 'Unread'">
                            mdi-email
                        </v-icon>
                        <v-icon color="primary" v-else>
                            mdi-email-open
                        </v-icon>
                    </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                    <div v-html="n.message"></div>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            color="primary"
                            @click="changeNotificationState(n, 'Unread')"
                        >
                            <v-icon color="primary">
                                mdi-email
                            </v-icon>
                            Mark as Unread
                        </v-btn>
                        <v-btn
                            text
                            color="error"
                            @click="changeNotificationState(n, 'Deleted')"
                        >   
                            <v-icon color="error">
                                mdi-trash-can
                            </v-icon>
                            Delete
                        </v-btn>
                        </v-card-actions>
                    </v-expansion-panel-content>
                </div>
            </v-expansion-panel>
        </v-expansion-panels>
        <v-snackbar v-model="successBar" :timeout="5000" color="success">
            <strong>{{ success }} </strong>
        </v-snackbar>
        <v-snackbar v-model="errorBar" :timeout="5000" color="red">
            <strong>{{ error }} </strong>
        </v-snackbar>
    </v-container>

    
</template>


<script>

// My beauty date formatter
import {BeautyDateTime} from '../../utils/BeautyDate' // eslint-disable-line no-unused-vars

export default {
    
    data () {
      return {
            showUnread: true,
            showDeleted: false,
            panel: [],
            notifications: [],
            unreadNotifications: [],
            undeletedNotifications: [],
            successBar: false,
            success: "",
            errorBar: false,
            error: "",
      }
    },

    methods: {

        beautifyDate(date) {
          if(date){
            return BeautyDateTime(new Date(date));
          }
          
        },

        displayAllNotifications(){
            if(this.showDeleted) {
                this.unfilteredNotifications();
            }
            else if(this.showUnread) {
                this.filterUnreadNotifications();
            }
            else {
                this.filterUndeletedNotifications();
            }
        },

        filterUnreadNotifications() {
            
            // Delete any previous content
            this.unreadNotifications = [];

            for(let n of this.$store.getters.notifications){
                //console.log(n);
                if(n.status === 'Unread'){
                    this.unreadNotifications.push(n);
                }
            }

            this.notifications = this.unreadNotifications;
        },
        filterUndeletedNotifications() {
            
            // Delete any previous content
            this.undeletedNotifications = [];

            for(let n of this.$store.getters.notifications){
                if(n.status === 'Unread' || n.status === 'Read'){
                    this.undeletedNotifications.push(n);
                }
            }

            this.notifications = this.undeletedNotifications;
        },

        unfilteredNotifications() {
            //this.notifications = this.$store.getters.notifications;
            this.notifications = [];

            for(let n of this.$store.getters.notifications){
                if(n.status === 'Deleted'){
                    this.notifications.push(n);
                }
            }
        },

        changeNotificationState(notification, newState) {
            
            if(notification.status === 'Deleted') {
                return;
            }

            //console.log("Changing notification state in: " + newState);

            const headers = {
                'Authorization': 'jwt ' + this.$store.getters.auth_jwt
            };

            const body = {
                "notificationId": notification._id,
                "newStatus": newState
            }

            this.$http.put(process.env.VUE_APP_API_ENDPOINT +'/common/setNotificationStatus', body, {headers})
            .then(response => {
                //console.log(response);
                if(response.status === 200){

                    //console.log(response.data);

                    // Operation went fine, it is possible to change also the local instance that will be update automaticaly when a new server sync occours
                    notification.status = newState;

                    if(notification.status ==='Unread') {
                        // Close the item to be coerent with the newly set value unreaded
                        this.panel = [];
                    }
                    // else if(newState ==='Read') {

                    // }
                    else if(notification.status ==='Deleted') {
                        // Refresh the list removing deleted notification
                        this.filterUndeletedNotifications();
                    }

                    if(notification.status ==='Unread' || notification.status ==='Deleted') {
                        this.success = response.data;
                        this.successBar = true;
                    }
                    
                }
            })
            .catch(err => {
                console.log('Error happened...');
                this.errorBar = true
                this.error = err.response.data
            });
        },

        deleteNotification(notificationId) {
            //console.log("Going to delete: " + notificationId);

            const headers = {
                'Authorization': 'jwt ' + this.$store.getters.auth_jwt
            };

            const body = {
                "notificationId": notificationId,
                "newStatus": "Deleted"
            }

            this.$http.put(process.env.VUE_APP_API_ENDPOINT +'/common/setNotificationStatus', body, {headers})
            .then(response => {
                //console.log(response);
                if(response.status === 200){

                    //console.log(response.data);
                    this.success = response.data;
                    this.successBar = true;

                    // Refresh the list removing deleted notification
                    this.filterUndeletedNotifications();
                }
            })
            .catch(err => {
                console.log('Error happened...');
                this.errorBar = true
                this.error = err.response.data
            });
        }
    },

    mounted: function() {

        this.displayAllNotifications();

        // Check for new notifications every minute? now set every ten sec for debug...
        // The correct way is that main trigger an event when it fetch new notifications from server
        // Evenmore... the main should trigger the event...as soon as new notification is found probably something is changed... so it is useful to update everthing...also in the other components
        // setInterval(this.displayAllNotifications, 10000);
        
    }
}
</script>