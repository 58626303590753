<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-icon class="ma-2" color="success" v-bind="attrs" v-on="on" outlined>
        mdi-book-plus
      </v-icon>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">Assign asset instance to user:</span>
      </v-card-title>
      <v-card-subtitle>
        <b> Remeber: </b> use with caution <br />
        Ensure that the user already has this asset.
      </v-card-subtitle>
      <v-card-text>
        <v-container>
          <v-container>
            <v-col class="d-flex" cols="12" sm="6">
              <v-select
                v-model="selectedUserId"
                :items="users"
                item-text="display_name"
                item-value="_id"
                label="Select the user"
                :rules="require_rule"
                dense
              ></v-select>
            </v-col>
          </v-container>
          <v-container>
            <v-col class="d-flex" cols="12" sm="6">
              <v-select
                v-model="selectedAssetId"
                :items="assets"
                item-text="name"
                item-value="_id"
                @change="selectAsset"
                label="Select the asset"
                :rules="require_rule"
                dense
              ></v-select>
            </v-col>
          </v-container>
          <v-container>
            <v-col class="d-flex" cols="12" sm="6">
              <v-select
                v-model="selectedInstanceId"
                :items="instances"
                item-text="dip_id"
                item-value="_id"
                label="Select the instance"
                :rules="require_rule"
                dense
              ></v-select>
            </v-col>
          </v-container>
          <v-container>
            <v-col cols="12" sm="12">
              <v-menu
                ref="menu1"
                v-model="dateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateFormatted"
                    label="Return Date"
                    persistent-hint
                    prepend-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                    :clearable="true"
                    :readonly="true"
                    required
                    :rules="require_rule"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="returnDate"
                  no-title
                  @input="
                    dateMenu = false;
                    dateFormatted = beautifyDate(returnDate);
                  "
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-container>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="close"> Back </v-btn>
        <v-btn color="success" text @click="LoanAsset"> Borrow </v-btn>
      </v-card-actions>
      <v-snackbar v-model="successBar" :timeout="5000" color="success">
        <strong>{{ success }} </strong>
      </v-snackbar>
      <v-snackbar v-model="errorBar" :timeout="5000" color="red">
        <strong>{{ error }} </strong>
      </v-snackbar>
    </v-card>
  </v-dialog>
</template>


<script>
//import ... from "./";
// My beauty date formatter
import { BeautyDate } from "../../utils/BeautyDate"; // eslint-disable-line no-unused-vars

export default {
  name: "AddLoanForm",

  components: {},

  props: {},

  data: () => ({
    dialog: false,
    successBar: false,
    success: "",
    errorBar: false,
    error: "",

    users: [],
    assets: [],
    instances: [],
    selectedUserId: null,
    selectedAssetId: null,
    selectedInstanceId: null,
    returnDate: null,
    dateMenu: false,
    dateFormatted: null,
    require_rule: [(value) => !!value || "Required."],
  }),

  methods: {
    beautifyDate(date) {
      
      //console.log(this.returnDate);
      
      if (date) {
        return BeautyDate(new Date(date));
      }
    },

    orderUser( u1, u2 ) {
      if ( u1.second_name < u2.second_name ){
        return -1;
      }
      if ( u1.second_name > u2.second_name ){
        return 1;
      }
      return 0;
    },
    orderAsset( a1, a2 ) {
      if ( a1.name < a2.name ){
        return -1;
      }
      if ( a1.name > a2.name ){
        return 1;
      }
      return 0;
    },

    fetchUsers() {
      // Post request to retrieve the user array
      //console.log("retrieving all the user that login at least once...");

      const headers = {
        Authorization: "Bearer " + this.$store.getters.auth_jwt,
      };

      // Make a request for a user with a given ID
      this.$http
        .get(process.env.VUE_APP_API_ENDPOINT + "/admin/getUsers", { headers })
        .then((response) => {
          // handle success
          //console.log(response.data);
          // Order alphabetically
          let orderedUsers = response.data.sort(this.orderUser);
          for(let ou of orderedUsers){
            ou.display_name = ou.second_name + ' (' + ou.username + ')';
          }
          this.users = orderedUsers;
        })
        .catch((error) => {
          // handle error
          console.log(error);
        })
        .then(function () {
          // always executed
        });
    },

    fetchAssets() {
      // Post request to retrieve the assets array
      //console.log("retrieving available assets...");

      const headers = {
        Authorization: "Bearer " + this.$store.getters.auth_jwt,
      };

      // Make a request for a user with a given ID
      this.$http
        .get(process.env.VUE_APP_API_ENDPOINT + "/common/assets", { headers })
        .then((response) => {
          this.assets = response.data.sort(this.orderAsset);
        })
        .catch((error) => {
          // handle error
          console.log(error);
        })
        .then(function () {
          // always executed
        });
    },

    selectAsset() {
      //console.log(this.selectedAssetId);
      this.fetchAvailableInstances(this.selectedAssetId);
    },

    // Retrieve assetInstace available
    fetchAvailableInstances(assetId) {
      // Used to update the list of available instances
      //console.log("retrieving available assets...");

      const headers = {
        Authorization: "Bearer " + this.$store.getters.auth_jwt,
      };

      // Make a request for available instances of an asset
      this.$http
        .get(
          process.env.VUE_APP_API_ENDPOINT +
            "/manager/getAvailableInstances/" +
            assetId,
          { headers }
        )
        .then((response) => {
          // handle success
          //console.log(response.data);
          this.instances = response.data;
        })
        .catch((error) => {
          // handle error
          console.log(error);
        })
        .then(function () {
          // always executed
        });
    },

    LoanAsset() {
      //console.log(this.username + " " + this.first_name + " " + this.last_name);
      const headers = {
        Authorization: "jwt " + this.$store.getters.auth_jwt,
      };
      const body = {
        userId: this.selectedUserId,
        assetId: this.selectedAssetId,
        instanceId: this.selectedInstanceId,
        returnDate: this.returnDate,
      };

      this.$http
        .post(process.env.VUE_APP_API_ENDPOINT + "/admin/addLoan", body, {
          headers,
        })
        .then((response) => {
          //console.log(response);
          if (response.status === 200) {
            this.$emit("loanAdded", response);
            this.close();
          }
        })
        .catch((err) => {
          console.log("Error happened...");
          this.errorBar = true;
          this.error = err.response.data;
        });
      //console.log(this.instances);
    },

    close() {
      // Emit that the reservation form has closed, we are going to refresh the asset list
      this.$emit("close");
      this.errorBar = false;
      this.dialog = false;
    },
  },

  created: function () {
    this.fetchUsers();
    this.fetchAssets();
  },
};
</script>