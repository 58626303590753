<template>
<v-item-group>
    <v-container>
        <h1>
            Borrowed items:
        </h1>
        <v-row v-if="$store.getters.loan.length == 0" class="ma-6">
            <h4>
                (No item currently borrowed)
            </h4>
        </v-row>
        <v-row>
            <BorrowedItemPreview v-for="assetInstance in $store.getters.loan"
                class="ma-2"
                :key="assetInstance._id"
                :assetInstance="assetInstance">
            </BorrowedItemPreview>
        </v-row>
    </v-container>
  </v-item-group>
</template>

<script>

import BorrowedItemPreview from './BorrowedItemPreview'

export default {
    
    name: 'Borrowed',

    components: {
      BorrowedItemPreview,

    },
}
</script>