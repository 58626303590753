<template>
  <v-timeline>
    <EventItem
      v-for="event in events"
      :key="event._id"
      :event="event"
    ></EventItem>
  </v-timeline>
</template>

<script>
//import ... from "./";
import EventItem from "./EventItem";

export default {
  name: "UserHistory",

  components: {
    EventItem,
  },

  props: {},

  data: () => ({
    events: [],
  }),

  methods: {
    

    fetchEvents() {
      // Post request to retrieve the user array
      //console.log("retrieving all the user events");

      const headers = {
        Authorization: "Bearer " + this.$store.getters.auth_jwt,
      };

      // Make a request for a user with a given ID
      this.$http
        .get(process.env.VUE_APP_API_ENDPOINT + "/common/getHistoryEvents", {
          headers,
        })
        .then((response) => {
          // handle success
          //console.log(response.data);
          this.events = response.data;
        })
        .catch((error) => {
          // handle error
          console.log(error);
        })
        .then(function () {
          // always executed
        });
    },
  },

  created: function () {
    this.fetchEvents();
  },
};
</script>