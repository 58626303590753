<template>
  <v-app id="inspire">
    <v-system-bar class="no-print" :color="testMode ? 'red' : 'primary'" :dark="true" app height="70">
      <v-spacer></v-spacer>
      <v-container fluid>
        <v-row align="center">
          <v-col class="d-flex ml-0 pl-0" cols="2">
            <v-img :src="require('@/assets/logo_dip_dark.png')"> </v-img>
          </v-col>
          <v-col>
            <v-btn v-if="$vuetify.breakpoint.mobile" class="ml-3" color="primary" text icon @click="setDrawer()">
              <v-icon>mdi-menu</v-icon>
            </v-btn>
          </v-col>
          <h1 v-if="testMode">TEST MODE !!!</h1>
          <v-spacer></v-spacer>
          <v-col class="d-flex" cols="2">
            <h3>{{ timestamp }}</h3>
          </v-col>
        </v-row>
      </v-container>
    </v-system-bar>

    <v-navigation-drawer v-model="drawer" class="no-print" app>
      <v-sheet color="secondary" :dark="true" class="pa-4">
        <v-avatar class="mb-4" color="success" size="64">
          <h1>{{ $store.getters.initials }}</h1>
        </v-avatar>
        <div>
          <h3>{{ userRole }}</h3>
        </div>
      </v-sheet>

      <v-divider></v-divider>
      <div v-if="userRole != 'User' && userRole != 'View-mode only'">
        <div v-if="userRole == 'Auditor'">
          <v-select :items="auditor_panels" :value="userRole + ' panel'" solo @change="setPanel"></v-select>
        </div>
        <div v-else-if="userRole == 'Manager'">
          <v-select :items="manager_panels" :value="userRole + ' panel'" solo @change="setPanel"></v-select>
        </div>
        <div v-else>
          <v-select :items="role_panels" :value="userRole + ' panel'" solo @change="setPanel"></v-select>
        </div>
      </div>

      <div v-else>
        <v-btn block @click="logout">Log Out</v-btn>
      </div>

      <v-list>
        <v-list-item v-for="link in links" :key="link.icon" link :to="link.route">
          <v-list-item-icon>
            <v-badge v-if="link.badgeRef != null && link.badgeRef != '0'" color="primary" :content="link.badgeRef">
              <v-icon>{{ link.icon }}</v-icon>
            </v-badge>

            <v-icon v-else>{{ link.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ link.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <v-container class="py-8 px-6" fluid>
        <v-layout row justify-space-around>
          <v-flex xs12 md10>
            <router-view />
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
// My beauty date formatter
import { BeautyDateTime } from "../utils/BeautyDate";

export default {
  data: () => ({
    drawer: true,
    links: null,
    testMode: false,
    // Used to notify the item in the cart
    //cartBadge: 0,
    count: 3,

    admin_links: {
      user_list: {
        icon: "groups",
        badgeRef: null,
        route: "modify_user",
        text: "User list",
      },
      admin_history: {
        icon: "mdi-history",
        badgeRef: null,
        route: "admin_history",
        text: "History",
      },
      stats: {
        icon: "trending_up",
        badgeRef: null,
        route: "statistics",
        text: "Stats",
      },
    },

    manager_links: {
      available_asset: {
        icon: "important_devices",
        badgeRef: null,
        route: "show_assets",
        text: "Available assets",
      },
      borrowed: {
        icon: "pending_actions",
        badgeRef: null,
        route: "manage_loans",
        text: "Borrowed",
      },
      give_away: {
        icon: "receipt_long",
        badgeRef: null,
        route: "to_implement4",
        text: "Give away",
      },
      user_reservations: {
        icon: "add_shopping_cart",
        badgeRef: null,
        route: "manage_reservations",
        text: "User Reservations",
      },
      // manager_history: {
      //   icon: "mdi-history",
      //   badgeRef: null,
      //   route: "manager_history",
      //   text: "History",
      // },
      add_new_asset: {
        icon: "playlist_add",
        badgeRef: null,
        route: "add_asset",
        text: "Add new asset",
      },
      modify_asset: {
        icon: "menu_open",
        badgeRef: null,
        route: "modify_assets",
        text: "Modify asset",
      },
    },

    auditor_links: {
      user_list: {
        icon: "groups",
        badgeRef: null,
        route: "user_list",
        text: "User list",
      },
      // asset_list: {
      //   icon: "important_devices",
      //   badgeRef: null,
      //   route: "to_do1a",
      //   text: "Asset list",
      // },
      // auditor_history: {
      //   icon: "mdi-history",
      //   badgeRef: null,
      //   route: "to_do2",
      //   text: "History",
      // },
      // stats: {
      //   icon: "trending_up",
      //   badgeRef: null,
      //   route: "to_do3",
      //   text: "Stats",
      // },
    },

    user_links: {
      available: {
        icon: "important_devices",
        badgeRef: null,
        route: "show_assets",
        text: "Available",
      },
      reservation: {
        icon: "shopping_cart",
        badgeRef: null,
        route: "cart",
        text: "Reservations",
      },
      borrowed: {
        icon: "pending_actions",
        badgeRef: null,
        route: "borrowed",
        text: "Borrowed",
      },
      owned: {
        icon: "all_inclusive",
        badgeRef: null,
        route: "to_implement8",
        text: "Owned",
      },
      history: {
        icon: "mdi-history",
        badgeRef: null,
        route: "/user_history",
        text: "History",
      },
      notifications: {
        icon: "notifications",
        badgeRef: null,
        route: "/notifications",
        text: "Notifications",
      },
      contactus: {
        icon: "contact_support",
        badgeRef: null,
        route: "/contactus",
        text: "Contact us",
      },
    },

    role_panels: [
      "Admin panel",
      "Manager panel",
      "Auditor panel",
      "User panel",
      "Log Out",
    ],
    manager_panels: ["Manager panel", "User panel", "Log Out"],
    auditor_panels: ["Auditor panel", "User panel", "Log Out"],
    userRole: "User",
    avatarColor: "",
    timestamp: "",
    setintervalRef: null,
  }),

  watch: {
    // Everytime the cart is updated we change the cartBadge ac
    "$store.getters.cart": function () {
      this.user_links.reservation.badgeRef = this.$store.getters.cart.length;
    },

    // Everytime notifications is changed we modify accordingly the notificationsBadge
    "$store.getters.notifications": function () {
      let nOfUnread = 0;
      for (let n of this.$store.getters.notifications) {
        if (n.status === "Unread") {
          nOfUnread++;
        }
      }

      this.user_links.notifications.badgeRef = nOfUnread;
    },
  },

  methods: {
    logout() {
      console.log("logout!");

      // console.log(this.setintervalRef);
      clearInterval(this.setintervalRef);

      // Delete the auth token stored
      //localStorage.clear();
      localStorage.removeItem("auth_token");

      // RESET VUEX STATUS
      // Set vuex status of valid jwt
      this.$store.commit("setAuthJWT", null);
      this.$store.commit("setValidJWT", false);
      // Reset also other vuex status info that will be reloaded on the next login
      this.$store.commit("setInitials", "");
      this.$store.commit("setUserRole", "");
      this.$store.commit("setCart", []);
      this.$store.commit("setLoan", []);
      this.$store.commit("setLoanHistory", []);
      this.$store.commit("setOwned", []);
      this.$store.commit("setNotifications", []);

      // It is also required to logout from CAS!!!
      window.location.href = process.env.VUE_APP_CAS_URL_LOGOUT; //"https://sso.staging.unimi.it:6443/logout";
    },

    setDrawer() {
      this.drawer = !this.drawer;
    },

    pickRandomColor() {
      //console.log('new color...')
      const randomColor = Math.floor(Math.random() * 16777215).toString(16);
      return "#" + randomColor;
    },

    getNow() {
      this.timestamp = BeautyDateTime(new Date());
    },

    setRoles() {
      this.userRole = this.$store.getters.userRole;
    },

    setPanel(rolePanel) {
      //console.log(rolePanel);
      if (rolePanel == "Admin panel") {
        this.links = this.admin_links;
        // Redirect to the default admin panel
        this.$router.replace('modify_user');
      } else if (rolePanel == "Manager panel") {
        this.links = this.manager_links;
        // Redirect to the default manager panel
        this.$router.replace('manage_loans');
      } else if (rolePanel == "Auditor panel") {
        this.links = this.auditor_links;
        // Redirect to the default auditor panel
        this.$router.replace('user_list');
      } else if (
        rolePanel == "User panel" ||
        rolePanel == "View-mode only panel"
      ) {
        this.links = this.user_links;
        // Redirect to the default user panel
        this.$router.replace('show_assets');
      } else if (rolePanel == "Log Out") {
        this.logout();
      }
    },

    // Get from Server API and store it in vuex
    getCart() {
      const headers = {
        Authorization: "Bearer " + this.$store.getters.auth_jwt,
      };

      // Make a request for a user with a given ID
      this.$http
        .get(process.env.VUE_APP_API_ENDPOINT + "/common/getCart", { headers })
        .then((response) => {
          //console.log(response);
          if (response.status === 200) {
            // console.log("Cart content retrieved");

            // Save the user cart into vuex status
            this.$store.commit("setCart", response.data);
            //console.log(this.$store.getters.cart);
          }
        })
        .catch((err) => {
          console.log("Error happened..." + err);
        });
    },

    // Get from Server API and store it in vuex
    getLoan() {
      const headers = {
        Authorization: "Bearer " + this.$store.getters.auth_jwt,
      };

      // Make a request for a user with a given ID
      this.$http
        .get(process.env.VUE_APP_API_ENDPOINT + "/common/getLoan", { headers })
        .then((response) => {
          //console.log(response);
          if (response.status === 200) {
            // console.log("Loan content retrieved");

            // Save the user loan into vuex status
            this.$store.commit("setLoan", response.data);

            // console.log(response.data);
          }
        })
        .catch((err) => {
          console.log("Error happened..." + err);
        });
    },

    //getLoanHistory
    //TODO

    //getOwned
    //TODO

    // Get notification specifing the current user
    // Get from Server API and store it in vuex
    getNotifications() {
      // Get request to retrieve the notifications array
      // console.log('retrieving notifications...');

      const headers = {
        Authorization: "Bearer " + this.$store.getters.auth_jwt,
      };

      // Make a request for a user with a given ID
      this.$http
        .get(process.env.VUE_APP_API_ENDPOINT + "/common/getNotifications", {
          headers,
        })
        .then((response) => {
          //console.log(response);
          if (response.status === 200) {
            // console.log("Notifications retrieved");

            // Save the user notifications into vuex status
            this.$store.commit("setNotifications", response.data);
            //console.log(this.$store.getters.notifications);

            // UPDATE ALSO CART CONTENT AND LOAN CONTENT (TODO to optimize...maybe is better to update only if a particular new notification is found)
            // Get the reservation cart content stored on the server
            this.getCart();

            // Get the loan list of the user, stored on the server
            this.getLoan();
          }
        })
        .catch((err) => {
          console.log("Error happened..." + err);
        });
    },
  },

  mounted: function () {
    //this.avatarColor = this.pickRandomColor();


    // Read env variable and convert to bool
    this.testMode = process.env.VUE_APP_TESTMODE == 'true';

    this.getNow();
    setInterval(this.getNow, 5000);

    // Set the default role found into the jwt and populate the panels
    this.setRoles();
    this.setPanel(this.userRole + " panel");

    // As soon as the menu is mounted update the cart badge
    this.user_links.reservation.badgeRef = this.$store.getters.cart.length;

    // Get the reservation cart content stored on the server
    this.getCart();

    // Get the loan list of the user, stored on the server
    this.getLoan();

    // Get the user notifications
    this.getNotifications();
    // Check for new notifications every minute? now set every ten sec for debug...
    this.setintervalRef = setInterval(this.getNotifications, 10000);

    // console.log('testMode -> ' + this.testMode);
  },
};
</script>

<style scoped>
@media print {
  body {
    overflow: auto;
    height: auto;
  }

  .scroll-y {
    height: auto;
    overflow: visible;
  }

  .v-main {
        padding: 0 !important;
    }

  .v-content {
    padding: 0 !important;
  }

  .no-print {
    display: none;
    padding: 0 !important;

  }
}
</style>
