<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-chip  class="ma-2" color="primary" v-bind="attrs" v-on="on" outlined>
        {{loanInstance.borrower.first_name + loanInstance.borrower.second_name + '(' + loanInstance.dip_id + ')'}}
      </v-chip>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">Asset: {{loanInstance.asset.name}}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row >   
            <v-col cols="12" sm="12" md="12">
            User: <strong> {{user.first_name}} {{user.second_name}} </strong> is returning instance: <strong> {{loanInstance.dip_id}} </strong> <br>
            Expected date of return:  <strong> {{beautifyDate(loanInstance.due_back)}} </strong>
            </v-col>           
          </v-row>
          <v-divider/>
          <br>
          <strong>Item returned condition: (0 -> very bad, 5 -> perfect) </strong>
          <v-rating
            v-model="rating_condition"
            background-color="orange lighten-3"
            color="orange"
            large
            half-increments
            hover
          ></v-rating>
          <v-divider/>
          <br>
          <strong>Item returned on time: (0 -> very late, 5 -> on time)</strong>
          <v-rating
            v-model="rating_ontime"
            background-color="orange lighten-3"
            color="orange"
            large
            half-increments
            hover
          ></v-rating>
        </v-container>
        
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="close"
        >
          Back
        </v-btn>
        <v-btn
          color="success"
          text
          @click="returnInstance"
        >
          Item returned
        </v-btn>
      </v-card-actions>
      <v-snackbar v-model="successBar" :timeout="5000" color="success">
        <strong>{{ success }} </strong>
      </v-snackbar>
      <v-snackbar v-model="errorBar" :timeout="5000" color="red">
        <strong>{{ error }} </strong>
      </v-snackbar>
    </v-card>
  </v-dialog>
</template>


<script>

  // My beauty date formatter
  import {BeautyDate} from '../../utils/BeautyDate' // eslint-disable-line no-unused-vars

  export default {

    name: 'HandleLoanFormByAsset',

    props: {
        user: Object,
        assetInstance: Object,
    },

    data: () => ({
        dialog: false,
        successBar: false,
        success: "",
        errorBar: false,
        error: "",
        // The instance we are going to loan, it could be the user reserved one or another chosen by manager
        loanInstance: null,
        rating_condition: 0,
        rating_ontime: 0,


    }),

    methods : {

        beautifyDate(date) {
          if(date){
            return BeautyDate(new Date(date));
          }
          
        },

        // Ready to return an assetInstance
        // This method reset the status fields of the assetInstance that we are goin to return
        returnInstance(){

          //console.log(this.user._id + ' -> ' + this.user.username + ' $ ' + this.loanInstance._id + ' -> ' + this.loanInstance.asset.name);
          // Prepare the message
          const headers = {
          'Authorization': 'jwt ' + this.$store.getters.auth_jwt
          };

          const body = {
            assetInstanceId: this.loanInstance._id,
            returnConditions: this.rating_condition,
            returnOntime: this.rating_ontime
          }

          this.$http.put(process.env.VUE_APP_API_ENDPOINT +'/manager/returnAssetInstance', body, {headers})
          .then(response => {
              //console.log(response);
              if(response.status === 200){

                  //console.log("Asset instance return done ");
                  //console.log(response.data);
                  this.$emit('returnDone', response.data);
                  this.close();

              }
          })
          .catch(err => {
              console.log('Error happened...');
              this.errorBar = true
              this.error = err.response.data
          });
        },

        close() {
            // Emit that the reservation form has closed, we are going to refresh the asset list
            this.$emit('instanceUpdated');
            this.$emit('close')
            this.errorBar = false;
            this.dialog = false;
        }
    },

    created: function (){ 

      this.loanInstance = this.assetInstance;
    }
  }
</script>