<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-chip
        class="ma-2"
        :color="
          assetInstance.status === 'Dismissed'
            ? 'red'
            : assetInstance.status === 'Maintenance'
            ? 'orange'
            : assetInstance.status === 'Reserved'
            ? 'purple'
            : assetInstance.status === 'Available'
            ? 'green'
            : 'primary'
        "
        v-bind="attrs"
        v-on="on"
        outlined
      >
        {{ assetInstance.dip_id }} -

        <v-icon v-if="deletable"> mdi-delete-forever </v-icon>
      </v-chip>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">Asset instance: {{ assetInstance.dip_id }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-flex xs12 md6>
              <v-text-field
                label="Specify the asset instance Dip_ID"
                v-model="instance_dip_id"
                required
              ></v-text-field>
            </v-flex>
          </v-row>
          <v-row>
            <v-flex xs12 md6>
              <v-checkbox
                label="Transferable"
                v-model="instance_transferable"
              ></v-checkbox>
            </v-flex>
          </v-row>
          <v-row>
            <v-flex xs12 md6>
              <v-checkbox
                label="Setup Required"
                v-model="instance_setup_required"
              ></v-checkbox>
            </v-flex>
          </v-row>
          <v-row>
            <v-flex xs12 md6>
              <v-select
                :items="instaceStatus"
                v-model="instance_status"
                label="Status"
              ></v-select>
            </v-flex>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn v-if="deletable" color="red" @click="deleteInstance"
          >Delete forever</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="close"> Back </v-btn>
        <v-btn color="success" text @click="updateInstance"> Update </v-btn>
      </v-card-actions>
      <v-snackbar v-model="successBar" :timeout="5000" color="success">
        <strong>{{ success }} </strong>
      </v-snackbar>
      <v-snackbar v-model="errorBar" :timeout="5000" color="red">
        <strong>{{ error }} </strong>
      </v-snackbar>
    </v-card>
  </v-dialog>
</template>


<script>
export default {
  name: "ModifyInstanceForm",

  props: {
    assetInstance: Object,
  },

  data: () => ({
    dialog: false,
    instance_id: null,
    instance_dip_id: null,
    instance_transferable: false,
    instance_setup_required: false,
    instance_status: null,
    instaceStatus: [
      "Available",
      //"Reserved",
      //"Borrowed",
      //"Transferred",
      "Maintenance",
      "Dismissed",
    ],
    deletable: false,
    successBar: false,
    success: "",
    errorBar: false,
    error: "",
  }),

  methods: {
    updateInstance() {
      const headers = {
        Authorization: "jwt " + this.$store.getters.auth_jwt,
      };

      //console.log(this.instance_id);

      const body = {
        instanceId: this.instance_id,
        instanceDipId: this.instance_dip_id,
        instanceTransferable: this.instance_transferable,
        instanceSetupRequired: this.instance_setup_required,
        instanceStatus: this.instance_status,
      };

      this.$http
        .put(
          process.env.VUE_APP_API_ENDPOINT + "/manager/modifyInstance",
          body,
          { headers }
        )
        .then((response) => {
          //console.log(response);
          if (response.status === 200) {
            this.$emit("instanceModified", response);
            this.close();
          }
        })
        .catch((err) => {
          console.log("Error happened...");
          this.errorBar = true;
          this.error = err.response.data;
        });
    },

    checkIfDeletable() {
      const headers = {
        Authorization: "jwt " + this.$store.getters.auth_jwt,
      };

      //console.log(this.instance_id);

      this.$http
        .get(
          process.env.VUE_APP_API_ENDPOINT +
            "/manager/isInstanceDeletable/" +
            this.instance_id,
          { headers }
        )
        .then((response) => {
          //console.log(response);
          if (response.status === 200) {
            this.deletable = response.data;
            //console.log(this.deletable);
            //console.log(typeof(this.deletable));
          }
        })
        .catch((err) => {
          console.log("Error happened checking deletable property...");
          this.errorBar = true;
          this.error = err.response.data;
        });
    },

    deleteInstance() {
      //console.log("Delete this instance...");

      const headers = {
        Authorization: "jwt " + this.$store.getters.auth_jwt,
      };

      const body = {
        instanceId: this.instance_id,
      };

      //console.log(this.instance_id);

      this.$http
        .put(
          process.env.VUE_APP_API_ENDPOINT + "/manager/deleteInstance",
          body,
          { headers }
        )
        .then((response) => {
          //console.log(response);
          if (response.status === 200) {
            this.$emit("instanceDeleted", response);
            this.close();
          }
        })
        .catch((err) => {
          console.log("Error deleting asset instance...");
          this.errorBar = true;
          this.error = err.response.data;
        });
    },

    close() {
      // Emit that the reservation form has closed, we are going to refresh the asset list
      this.$emit("close");
      this.errorBar = false;
      this.dialog = false;
    },
  },

  created: function () {
    this.instance_id = this.assetInstance._id;
    this.instance_dip_id = this.assetInstance.dip_id;
    this.instance_transferable = this.assetInstance.transferable;
    this.instance_setup_required = this.assetInstance.setup_required;
    this.instance_status = this.assetInstance.status;
  },

  mounted: function () {
    // Verify if this instance could be deleted checking its history
    this.checkIfDeletable();
  },
};
</script>