<template>
<v-item-group>
    <v-container>
        <h1>
            For any issue or information please contact us at: <a href="mailto:prestito@di.unimi.it">prestito@di.unimi.it</a>
        </h1>
    </v-container>
  </v-item-group>
</template>

<script>

export default {
    
    name: 'Contactus',

    components: {

    },
}
</script>