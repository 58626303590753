<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-icon class="ma-2" color="success" v-bind="attrs" v-on="on" outlined>
        mdi-account-plus
      </v-icon>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">Create new user:</span>
      </v-card-title>
      <v-card-subtitle>
        <b> Remeber: </b> users are auto-created at first login. <br>
        This feature must be used only to assign objects to users never logged in.
      </v-card-subtitle>
      <v-card-text>
        <v-container>
          <v-container>
            <v-text-field
              ref="username_field"
              label="eMail"
              v-model="email"
              required
              :rules="email_rule"
              hide-details="auto"
              placeholder="Username of the new user"
              outlined
            ></v-text-field>
          </v-container>
          <v-container>
            <v-text-field
              ref="first_name_field"
              label="First name"
              v-model="first_name"
              hide-details="auto"
              placeholder="First name of the new user"
              outlined
            ></v-text-field>
          </v-container>
          <v-container>
            <v-text-field
              ref="last_name_field"
              label="Last name"
              v-model="last_name"
              hide-details="auto"
              placeholder="Last name of the new user"
              outlined
            ></v-text-field>
          </v-container>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="close"> Back </v-btn>
        <v-btn color="success" text @click="AddUser"> Create new </v-btn>
      </v-card-actions>
      <v-snackbar v-model="successBar" :timeout="5000" color="success">
        <strong>{{ success }} </strong>
      </v-snackbar>
      <v-snackbar v-model="errorBar" :timeout="5000" color="red">
        <strong>{{ error }} </strong>
      </v-snackbar>
    </v-card>
  </v-dialog>
</template>


<script>
//import ... from "./";

export default {
  name: "AddUserForm",

  components: {},

  props: {},

  data: () => ({
    dialog: false,
    successBar: false,
    success: "",
    errorBar: false,
    error: "",

    email: null,
    first_name: null,
    last_name: null,

    email_rule: [
      (value) => !!value || "Required.",
      (v) =>
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        "E-mail must be valid",
    ],
  }),

  methods: {
    AddUser() {
      //console.log(this.username + " " + this.first_name + " " + this.last_name);
        const headers = {
          Authorization: "jwt " + this.$store.getters.auth_jwt,
        };
        const body = {
          email: this.email,
          first_name: this.first_name,
          last_name: this.last_name
        };

        this.$http
          .post(process.env.VUE_APP_API_ENDPOINT + "/admin/addUser", body, {
            headers,
          })
          .then((response) => {
            //console.log(response);
            if (response.status === 200) {
              this.$emit("userAdded", response);
              this.close();
            }
          })
          .catch((err) => {
            console.log("Error happened...");
            this.errorBar = true;
            this.error = err.response.data;
          });
        //console.log(this.instances);
    },

    close() {
      // Emit that the reservation form has closed, we are going to refresh the asset list
      this.$emit("close");
      this.errorBar = false;
      this.dialog = false;
    },
  },

  created: function () {},
};
</script>