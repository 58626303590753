<template>
  <v-timeline-item
    :color="
      event.action == 'Creation'
        ? 'pink'
        : event.action == 'Reservation'
        ? 'orange'
        : event.action == 'CancelReservation'
        ? 'red'
        : event.action == 'Borrow'
        ? 'primary'
        : event.action == 'Return'
        ? 'success'
        : 'black'
    "
    small
  >
    <div class="py-4">
      <h2 :class="`headline font-weight-bold mb-4 primary--text`">
        {{ event.action }}
      </h2>
      <div>Asset: {{event.assetInstance.asset.name}} - Instance:{{event.assetInstance.dip_id }} @ {{ beautifyDate(event.date) }}</div>
    </div>
  </v-timeline-item>
</template>

<script>

// My beauty date formatter
import { BeautyDate } from "../../../utils/BeautyDate"; // eslint-disable-line no-unused-vars

export default {
  name: "UserHistoryEventItem",

  props: {
    event: Object,
  },

  data: () => ({}),

  components: {},

  methods: {
    beautifyDate(date) {
      //console.log(this.returnDate);

      if (date) {
        return BeautyDate(new Date(date));
      }
    },
  },
};
</script>