<template>
    <v-container>   
            <v-card>
                <v-container>
                    {{this.name}} n.{{this.id}}
                    <v-flex xs12 md6>
                        <v-text-field label="Specify the asset Dip_ID" v-model="instance_dip_id" @change="instanceUpdated" required></v-text-field>
                    </v-flex>
                    <v-flex xs12 md6>          
                        <v-checkbox label="Transferable" v-model="instance_transferable" @change="instanceUpdated"></v-checkbox>
                    </v-flex>
                    <v-flex xs12 md6>          
                        <v-checkbox label="Setup required" v-model="instance_setup_required" @change="instanceUpdated"></v-checkbox>
                    </v-flex>
                </v-container>
            </v-card>
    </v-container>
</template>

<script>
  export default {
    
    name: 'AssetInstance',

    props: {
          name: String,
          id: Number,
          transferable: Boolean,
          setup_required: Boolean,
    },

    data () {
      return {

        instance_dip_id: "",
        instance_transferable: this.transferable,
        instance_setup_required: this.setup_required,
        creation_date: null
       
      }
    },

    methods: {
      instanceUpdated() {

        let instance_update = {
          // The array index of the asset.instances to modify
          index: this.id,

          // The id assigned by the Dept. of Computer Science
          dip_id: this.instance_dip_id,

          transferable: this.instance_transferable,
          setup_required: this.instance_setup_required,

          // Better to ovverride it by server side?
          creation_date: Date.now()

        } 
        this.$emit('instanceUpdated', instance_update)
      }
    },
  }
</script>