<template>
  <v-timeline-item
    :color="
      event.action == 'Creation'
        ? 'pink'
        : event.action == 'Reservation'
        ? 'orange'
        : event.action == 'CancelReservation'
        ? 'red'
        : event.action == 'Borrow'
        ? 'primary'
        : event.action == 'Return'
        ? 'success'
        : 'black'
    "
    small
  >
    <div class="py-4">
      <h2 :class="`headline font-weight-bold mb-4 primary--text`">
        {{ event.action }}
      </h2>
      <div>{{ event.user.username }} @ {{ event.date }}</div>
    </div>
  </v-timeline-item>
</template>

<script>
// My beauty date formatter
import { BeautyDate } from "../../../utils/BeautyDate"; // eslint-disable-line no-unused-vars

export default {
  name: "InstanceHistoryEventItem",

  props: {
    asset: Object,
    assetInstance: Object,
    event: Object,
  },

  data: () => ({}),

  components: {},

  methods: {
    beautifyDate(date) {
      //console.log(this.returnDate);

      if (date) {
        return BeautyDate(new Date(date));
      }
    },
  },
};
</script>