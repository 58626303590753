<template>
    <v-item>
        <v-lazy
            v-model="isActive"
            :options="{ threshold: .5}"
            min-height="200"
            width = "250"
            transition="fade-transition"
        >
            <v-card class="mx-1" >          
                <v-img
                class="mx-0"
                v-if="this.assetInstance.asset.thumbnail"
                :lazy-src="apiEndpoint +'/images/thumbnails/placeholder.png'"
                :src="apiEndpoint +'/' + this.assetInstance.asset.thumbnail"
                width="100"
                >
                    <template v-slot:placeholder>
                        <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                        >
                        <v-progress-circular
                            indeterminate
                            color="grey lighten-5"
                        ></v-progress-circular>
                        </v-row>
                    </template>

                    <v-btn color="red" elevation="3" fab small @click="removeReservation">
                        <v-icon>mdi-cart-remove</v-icon>
                    </v-btn>
                </v-img>
                
                <v-card-title>
                    {{this.assetInstance.asset.name}}
                </v-card-title>
                <v-card-text>
                    {{this.assetInstance.asset.description}}
                </v-card-text>
            </v-card>
        </v-lazy>
    </v-item>
</template>


<script>

export default {
    
    name: 'CartItemPreview',

    props: {
          assetInstance: Object,
    },

    data: () => ({

      isActive: false,
      apiEndpoint: process.env.VUE_APP_API_ENDPOINT,
    }),

    components: {
    },

    methods:  {

        removeReservation() {
            //console.log(this.assetInstance);

            // Prepare the message
            const headers = {
            'Authorization': 'jwt ' + this.$store.getters.auth_jwt
            };

            //console.log(headers);

            const body = {

                // Asset id (What)
                "assetInstance": this.assetInstance._id,
            }

            this.$http.delete(process.env.VUE_APP_API_ENDPOINT +'/common/removeReservation', { headers: headers, data: body})
            .then(response => {
                //console.log(response);
                if(response.status === 200){

                    //console.log("Reservation removed");
                    //console.log(response.data);
                    
                    // Save the user cart into vuex status
                    this.$store.commit('setCart', response.data);
                    //console.log(this.$store.getters.cart);
                }
            })
            .catch(err => {
                console.log('Error happened...' + err);
            });
        }
    }
}
</script>