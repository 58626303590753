<template>
  <div>
    <!--<Login v-if=!$store.getters.isAuth @auth="onLogin"></Login>
    <Main v-else> </Main> ### new cas implementation### -->
    <Main v-if="$store.getters.isAuth"> </Main>
    <v-alert v-if="serviceDown" border="top" color="red lighten-2">
      Prestito service is temporarily down. Please try again later...
      <!-- TODO: IMPROVE ERROR MESSAGE Up to now this happen also if user was not correctly associated into IPA -->
    </v-alert>
  </div>
</template>

<script>
//import ObjectsList from './components/ObjectsList'
//import Login from './components/Login'
import Main from "./components/Main";

// Used to decode the auth jwt received
import jwt_decode from "jwt-decode";

const URL = require('url');

export default {
  name: "App",

  components: {
    // ObjectsList,
    //Login,
    Main,
  },

  data: () => ({
    title: "UNIMI - Computer Science Dept.",
    serviceDown: false,
    myURL: null,
  }),
  methods: {
    // If we do not have a valid jwt then we need to require a valid ticket to cas.
    // When we have the ticket we need to validate it to the backend app.
    validate: function (service, ticket) {
      // Prepare the message
      const headers = {};

      const body = {
        // Service to validate with cas
        service: service,
        // Ticket received by cas
        ticket: ticket,
      };

      this.$http
        .post(
          process.env.VUE_APP_API_ENDPOINT + "/common/validateTicket",
          body,
          { headers }
        )
        .then((response) => {
          //console.log(response);
          if (response.status === 200) {
            console.log("Ticket validated");
            //console.log(response.data);

            // Store jwt data received
            this.onValidate(response.data.auth_token);
          } else if (response.status === 404) {
            console.log("The service is down. Please try again later...");
            this.serviceDown = true;
          }
        })
        .catch((err) => {
          console.log("Cannot validate cas ticket. The service is down. Please try again later...");
          this.serviceDown = true;
          console.log(err.response);
        });
    },

    // Probably this method will be renamed onValidate
    // When the backend validate the ticket and the user then send back a valid jwt for this user that will be
    // saved into the local storage with this method.

    // This method is triggered when the subcomponent login execute a valid login action on the ipa server
    // jwt (value) is passed back by the login component
    onValidate: function (auth_token) {

      console.log("onValidate");

      try {
        // Parse jwt content and fulfill some infoes
        var decoded = jwt_decode(auth_token);



        // User first and second name initials (ui Avatar)
        this.$store.commit("setInitials", decoded.initials[0]);
        // User role found into the token
        this.$store.commit("setUserRole", decoded.role);
        // Load the valid obtained jwt
        this.$store.commit("setAuthJWT", auth_token);
        // Set that we are currently authorized (mainly used to show part of the UI)
        this.$store.commit("setValidJWT", true);
      }
      catch (e) {
        console.log("Error in onValidate");
        console.log(e);
      }


      // If ticket present into the url redirect to home
      if (this.$route.query.ticket) {
        this.$router.push("/");
      }
    },

    redirectToCas: function () {
      //console.log(process.env.CAS_URL_LOGIN);
      window.location.href = process.env.VUE_APP_CAS_URL_LOGIN + this.myURL; //"https://sso.staging.unimi.it:6443/login?service=http%3A%2F%2Flocalhost:8080";
    },

    isJwtValid: function (jwt) {
      // Ask to backend if current found jwt is valid, if it is then use it else ask for a new jwt

      // Prepare the message
      const headers = {
        Authorization: "jwt " + jwt,
      };

      const body = {};

      this.$http
        .post(process.env.VUE_APP_API_ENDPOINT + "/common/jwtVerify", body, {
          headers,
        })
        .then((response) => {
          //console.log(response);
          if (response.status === 200) {
            //console.log(response.data);
            if (response.data.status === "valid") {
              console.log("JWT Valid");
              console.log("-> Valid token found -> autologin");
              // Valid token found, we reload the jwt user info passing from the common onValidate method
              this.onValidate(jwt);
            }
          } else if (response.status === 404) {
            console.log("The service is down. Please try again later...");
            this.serviceDown = true;
          }
        })
        .catch((err) => {
          if (err.response) {
            console.log(err.response.data);
          } else {
            console.log(err);
          }

          this.$store.commit("setAuthJWT", null);
          this.$store.commit("setValidJWT", false);
          localStorage.removeItem("auth_token");
          console.log("JWT verification error...redirectin to CAS for new authentication!");
          this.redirectToCas();
        });
    },
  },

  // As soon as the app is created we check if valid token was found (to avoid login if not necessary)
  // and we try to retrieve data from api
  created: function () {
    //console.log(process.env.VUE_APP_API_ENDPOINT);
    console.log("Prestito App Version: " + process.env.VUE_APP_VERSION);
    console.log("Prestito App TestMode: " + process.env.VUE_APP_TESTMODE);
    //console.log(process.env.VUE_APP_CAS_URL_LOGIN);

    // Extract the url in the window, used to validate the service to CAS
    let currentUrl = URL.parse(window.location.href);
    this.myURL = encodeURIComponent(currentUrl.protocol + '//' + currentUrl.host + currentUrl.pathname);
    //console.log(this.myURL);

    // Reset if error
    this.serviceDown = false;


    // Try to get auth token, if empty it automatically try to load it from local storage
    // If empty check if we have stored it on local storage
    console.log("looking for stored jwt");
    const jwt = localStorage.getItem("auth_token");


    console.log("Stampiamo il token del local storage: ");
    console.log(jwt);
    // FTF we are verifing if a jwt is present, if it is then we check for validity
    // If not present then we need to talk with cas for authentication
    // This means that the first time we arrived here without a jwt or with an invalid jwt
    // We were redirected to cas and if login is fine he redirect us here again with a ticket
    // So we should also check if we land here with a ticket into the url
    // If it is present than it means that we were redirected here by cas after a successful login and we should now send the
    // ticket for validation to our backend.
    // Check validity (to check why i need both, the first check if the jwt variable  )
    if (!(jwt === null)) {
      console.log("-> Found token, check if valid");

      this.isJwtValid(jwt);
    } else {
      // JWT null or invalid, check if in the url there is the cas ticket if not redirect else call backend to validate
      if (this.$route.query.ticket) {
        // probably we were redirected here by CAS, it's now time to call our backend, pass the ticket and wait for validation
        console.log("Ok pass the ticket to the backend for validation");
        //console.log(this.$route.query.ticket); // outputs 'CAS token'
        this.validate(this.myURL, this.$route.query.ticket);
      } else {
        console.log("-> null or invalid or Expired token...go to cas to obtain a new ticket");
        this.redirectToCas();
      }
    }
  },
};
</script>
