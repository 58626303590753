<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-chip class="ma-2" color="success" v-bind="attrs" v-on="on" outlined>
        <v-icon left> mdi-server-plus </v-icon>
        Add NEW
      </v-chip>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">Asset: {{ asset.name }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-text-field
              label="Number of Instances"
              type="number"
              v-model="n_of_instances"
              @blur="rebuildInstances"
              @change="rebuildInstances"
              required
            ></v-text-field>
            <v-container>
              <AssetInstance
                v-for="n in Number(n_of_instances)"
                :key="n"
                :name="asset.name"
                :id="n - 1 + asset.instances.length"
                :transferable="asset.transferable"
                :setup_required="asset.setup_required"
                @instanceUpdated="updateInstance"
              >
              </AssetInstance>
            </v-container>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="close"> Back </v-btn>
        <v-btn
          v-if="n_of_instances > 0"
          color="success"
          text
          @click="AddInstances"
        >
          Create new
        </v-btn>
      </v-card-actions>
      <v-snackbar v-model="successBar" :timeout="5000" color="success">
        <strong>{{ success }} </strong>
      </v-snackbar>
      <v-snackbar v-model="errorBar" :timeout="5000" color="red">
        <strong>{{ error }} </strong>
      </v-snackbar>
    </v-card>
  </v-dialog>
</template>


<script>
import AssetInstance from "./AssetInstance";

export default {
  name: "AddInstancesForm",

  components: {
    AssetInstance,
  },

  props: {
    asset: Object,
  },

  data: () => ({
    dialog: false,
    successBar: false,
    success: "",
    errorBar: false,
    error: "",

    n_of_instances: 0,
    instances: [],
  }),

  methods: {
    // As soon the number of instances is set or custom instance name is set then rebuild the instances array
    // If the asset is trasferable no need to set a specific dip_id, we use the autogen one
    rebuildInstances() {
      //console.log("rebuilding instances...");
      // Reset the instances
      this.instances = [];
      let instance_name = "";

      instance_name = this.asset.name;

      //console.log("n. of instances: " + this.asset.instances.length);

      for (let i = 0; i < this.n_of_instances; i++) {
        let instance = {
          dip_id: instance_name + "_" + (i + this.asset.instances.length),
          creation_date: Date.now(),
          transferable: false,
          setup_required: false,
        };
        //console.log(instance);
        this.instances.push(instance);
      }
      //console.log(this.asset.instances)
    },

    // In case the asset is not transferable we update each modified instance into the array
    // when one of the instance dip_id or transferable attribute is changed into the AssetInstance component we trigger this event
    updateInstance(newVal) {
      // Modify the values of this instance into the array
      // Because autonaming starts from the last created instance name +1, we need to add and remove to index the asset.instances.length
      //console.log(newVal);
      //console.log(newVal.index - this.asset.instances.length);

      if (newVal.dip_id != "") {
        this.instances[newVal.index - this.asset.instances.length].dip_id =
          newVal.dip_id;
      }
      this.instances[newVal.index - this.asset.instances.length].creation_date =
        newVal.creation_date;
      this.instances[newVal.index - this.asset.instances.length].transferable =
        newVal.transferable;

      this.instances[newVal.index - this.asset.instances.length].setup_required =
        newVal.setup_required;
    },

    AddInstances() {
      const headers = {
        Authorization: "jwt " + this.$store.getters.auth_jwt,
      };

      const body = {
        assetId: this.asset._id,
        newInstances: this.instances,
      };
      this.$http
        .post(
          process.env.VUE_APP_API_ENDPOINT + "/manager/addInstances",
          body,
          {
            headers,
          }
        )
        .then((response) => {
          //console.log(response);
          if (response.status === 200) {
            this.$emit("instancesAdded", response);
            this.close();
          }
        })
        .catch((err) => {
          console.log("Error happened...");
          this.errorBar = true;
          this.error = err.response.data;
        });
      //console.log(this.instances);
    },

    close() {
      // Emit that the reservation form has closed, we are going to refresh the asset list
      this.$emit("close");
      this.errorBar = false;
      this.dialog = false;

      // Reset input fields
      this.n_of_instances = 0;
      this.instances = [];
    },
  },

  created: function () {},
};
</script>