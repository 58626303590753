<template>
  <v-timeline-item>{{event.action}} of {{event.assetInstance.dip_id}} ({{beautifyDate(event.date)}})</v-timeline-item>
</template>

<script>

// My beauty date formatter
import { BeautyDate } from "../../utils/BeautyDate"; // eslint-disable-line no-unused-vars

export default {
  name: "EventItem",

  props: {
    event: Object,
  },

  data: () => ({}),

  components: {},

  methods: {
    beautifyDate(date) {
      //console.log(this.returnDate);

      if (date) {
        return BeautyDate(new Date(date));
      }
    },
  },
};
</script>